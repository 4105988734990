import {createStore} from 'vuex'
import axios from 'axios'

export default createStore({
  state: {
    globalAlerts: [],
    settings: {
      time_format: null,
      calendar_format: null,
      start_time: null,
      end_time: null,
      slot_duration: null,
      first_day: null,
      time_zone: null,
      todayDate: {
        day: null,
        week: null,
      },
    },

    modalType: null,
    itsWaitingEvent: false,

    days: null,
    editEvent: null,
    newEvent: null,

    updateData: false,
    acceptChange: false,

    dateForNewEvents: null,
  },
  getters: {},
  mutations: {
    setGlobalAlerts(state, globalAlerts) {
      for (let i in state.globalAlerts) {
        if (state.globalAlerts[i].id == globalAlerts.id) {
          state.globalAlerts.splice(i, 1);
          break;
        }
      }
      state.globalAlerts.push(globalAlerts);
    },
    deleteGlobalAlert(state, alertID) {
      for (let i in state.globalAlerts) {
        if (state.globalAlerts[i].id == alertID) {
          state.globalAlerts.splice(i, 1);
          break;
        }
      }
    },
    setEditEvent(state, editEvent) {
      state.editEvent = Object.assign({}, editEvent);
      state.editEvent.dateFrom = new Date(editEvent.dateFrom);
      state.editEvent.dateTo = new Date(editEvent.dateTo);
    },
    setNewEvent(state, newEvent) {
      state.newEvent = newEvent;
    },
    setItsWaitingEvent(state, bool) {
      state.itsWaitingEvent = bool;
    },
    setDays(state, days) {
      state.days = days;
    },
    setSettings(state, settings) {
      state.settings = settings;
    },
    setModalType(state, modalType) {
      state.modalType = modalType;
      state.updateData = false;
      if(modalType != 'add'){
        state.itsWaitingEvent = false;
      }

    },
    setUpdateData(state, bool) {
      state.updateData = bool;
    },
    setAcceptChange(state, bool) {
      state.acceptChange = bool;
    },
    setDateForNewEvent(state, date) {
      state.dateForNewEvents = date;
    },

  },
  actions: {
  },
  modules: {}
})
