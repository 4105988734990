<template>
  <div class="background_modal" @click="checkUpdate()">
    <div class="modal" @click.stop>
      <div class="modal_close_btn" @click="checkUpdate()">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <path d="M16 8L8 16M8.00001 8L16 16" stroke="#000000" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"></path>
          </g>
        </svg>
      </div>
      <h2>Настройки</h2>
      <div class="modal_content">
        <div class="modal_content_elem">
          <p>Формат времени</p>
          <select v-model="local_settings.time_format">
            <option value="24">24 ч</option>
            <option value="12">12 ч</option>
          </select>
        </div>
        <div class="modal_content_elem">
          <p>Вид календаря</p>
          <select v-model="local_settings.calendar_format">
            <option value="day">День</option>
            <option value="week">Неделя</option>
            <option value="month">Месяц</option>
          </select>
        </div>
        <div class="modal_content_elem">
          <p>Начало дня</p>
          <select v-if="this.local_settings.time_format == '24'" v-model="local_settings.start_time">
            <option value="01:00">01:00</option>
            <option value="02:00">02:00</option>
            <option value="03:00">03:00</option>
            <option value="04:00">04:00</option>
            <option value="05:00">05:00</option>
            <option value="06:00">06:00</option>
            <option value="07:00">07:00</option>
            <option value="08:00">08:00</option>
            <option value="09:00">09:00</option>
            <option value="10:00">10:00</option>
            <option value="11:00">11:00</option>
            <option value="12:00">12:00</option>
            <option value="13:00">13:00</option>
            <option value="14:00">14:00</option>
            <option value="15:00">15:00</option>
            <option value="16:00">16:00</option>
            <option value="17:00">17:00</option>
            <option value="18:00">18:00</option>
            <option value="19:00">19:00</option>
            <option value="20:00">20:00</option>
            <option value="21:00">21:00</option>
            <option value="22:00">22:00</option>
            <option value="23:00">23:00</option>
            <option value="24:00">24:00</option>
          </select>
          <select v-if="this.local_settings.time_format == '12'" v-model="local_settings.start_time">
            <option value="01:00">01 AM</option>
            <option value="02:00">02 AM</option>
            <option value="03:00">03 AM</option>
            <option value="04:00">04 AM</option>
            <option value="05:00">05 AM</option>
            <option value="06:00">06 AM</option>
            <option value="07:00">07 AM</option>
            <option value="08:00">08 AM</option>
            <option value="09:00">09 AM</option>
            <option value="10:00">10 AM</option>
            <option value="11:00">11 AM</option>
            <option value="12:00">12 PM</option>
            <option value="13:00">01 PM</option>
            <option value="14:00">02 PM</option>
            <option value="15:00">03 PM</option>
            <option value="16:00">04 PM</option>
            <option value="17:00">05 PM</option>
            <option value="18:00">06 PM</option>
            <option value="19:00">07 PM</option>
            <option value="20:00">08 PM</option>
            <option value="21:00">09 PM</option>
            <option value="22:00">10 PM</option>
            <option value="23:00">11 PM</option>
            <option value="24:00">12 PM</option>
          </select>
        </div>
        <div class="modal_content_elem">
          <p>Конец дня</p>
          <select v-if="this.local_settings.time_format == '24'" v-model="local_settings.end_time">
            <option value="01:00">01:00</option>
            <option value="02:00">02:00</option>
            <option value="03:00">03:00</option>
            <option value="04:00">04:00</option>
            <option value="05:00">05:00</option>
            <option value="06:00">06:00</option>
            <option value="07:00">07:00</option>
            <option value="08:00">08:00</option>
            <option value="09:00">09:00</option>
            <option value="10:00">10:00</option>
            <option value="11:00">11:00</option>
            <option value="12:00">12:00</option>
            <option value="13:00">13:00</option>
            <option value="14:00">14:00</option>
            <option value="15:00">15:00</option>
            <option value="16:00">16:00</option>
            <option value="17:00">17:00</option>
            <option value="18:00">18:00</option>
            <option value="19:00">19:00</option>
            <option value="20:00">20:00</option>
            <option value="21:00">21:00</option>
            <option value="22:00">22:00</option>
            <option value="23:00">23:00</option>
            <option value="24:00">24:00</option>
          </select>
          <select v-if="this.local_settings.time_format == '12'" v-model="local_settings.end_time">
            <option value="01:00">01 AM</option>
            <option value="02:00">02 AM</option>
            <option value="03:00">03 AM</option>
            <option value="04:00">04 AM</option>
            <option value="05:00">05 AM</option>
            <option value="06:00">06 AM</option>
            <option value="07:00">07 AM</option>
            <option value="08:00">08 AM</option>
            <option value="09:00">09 AM</option>
            <option value="10:00">10 AM</option>
            <option value="11:00">11 AM</option>
            <option value="12:00">12 PM</option>
            <option value="13:00">01 PM</option>
            <option value="14:00">02 PM</option>
            <option value="15:00">03 PM</option>
            <option value="16:00">04 PM</option>
            <option value="17:00">05 PM</option>
            <option value="18:00">06 PM</option>
            <option value="19:00">07 PM</option>
            <option value="20:00">08 PM</option>
            <option value="21:00">09 PM</option>
            <option value="22:00">10 PM</option>
            <option value="23:00">11 PM</option>
            <option value="24:00">12 PM</option>
          </select>
        </div>

        <div class="modal_main_info_elem error" v-if="local_settings.start_time == local_settings.end_time || local_settings.end_time < local_settings.start_time">
          <span>Конец дня должен быть позже, чем начало дня</span>
        </div>

        <div class="modal_content_elem">
          <p>Длительность слота</p>
          <select v-model="local_settings.slot_duration">
            <option value="10">10 мин</option>
            <option value="20">20 мин</option>
            <option value="30">30 мин</option>
            <option value="60">60 мин</option>
            <option value="120">120 мин</option>
          </select>
        </div>
        <div class="modal_content_elem">
          <p>Начало недели</p>
          <select v-model="local_settings.first_day">
            <option value="0">Sunday</option>
            <option value="1">Monday</option>
            <option value="2">Tuesday</option>
            <option value="3">Wednesday</option>
            <option value="4">Thursday</option>
            <option value="5">Friday</option>
            <option value="6">Saturday</option>
          </select>
        </div>
      </div>
      <div class="modal_control">
        <button @click="this.setModalType(null)">Отмена</button>
        <button :class="{disabled: local_settings.start_time == local_settings.end_time || local_settings.end_time < local_settings.start_time}" @click="saveSettings(true)">Сохранить</button>
      </div>
    </div>
    <div class="accept_close" v-if="accept_change || this.acceptChange">
      <div class="modal_close" @click="accept_change = false; this.setAcceptChange(false)">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <path d="M16 8L8 16M8.00001 8L16 16" stroke="#000000" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"></path>
          </g>
        </svg>
      </div>
      <h2>Сохранить изменения?</h2>
      <div class="modal_control">
        <button @click="this.setModalType(null)">Нет</button>
        <button :class="{disabled: local_settings.start_time == local_settings.end_time || local_settings.end_time < local_settings.start_time}" @click="saveSettings(true)">Сохранить</button>
      </div>
    </div>
  </div>

</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  data() {
    return {
      local_settings: {
        time_format: null,
        calendar_format: null,
        start_time: null,
        end_time: null,
        slot_duration: null,
        first_day: null,
        time_zone: null,
        todayDate: {
          day: null,
          week: null,
        },
      },
      accept_change: false,
      timeout: true,
    }
  },
  mounted() {
    this.loadSettings()
        .then(res => {
          this.timeout = false;
        })

  },
  methods: {
    async loadSettings(){
      this.local_settings = Object.assign({}, this.settings);
      return true;
    },
    loadSettingsInCoockie(){
      localStorage.time_format = this.local_settings.time_format;
      localStorage.calendar_format = this.local_settings.calendar_format;
      localStorage.start_time = this.local_settings.start_time;
      localStorage.end_time = this.local_settings.end_time;
      localStorage.first_day = this.local_settings.first_day;
      localStorage.slot_duration = this.local_settings.slot_duration;
    },
    saveSettings(close = false) {
      this.setSettings(this.local_settings);
      this.loadSettingsInCoockie();
      this.$emit('updateCalendar');
      if(close){
        this.setModalType(null);
      }
    },
    checkUpdate(){
      if(this.updateData){
        this.accept_change = true;
      }else{
        this.setModalType(null);
      }
    },
    ...mapMutations({
      setSettings: 'setSettings',
      setModalType: 'setModalType',
      setUpdateData: 'setUpdateData',
      setAcceptChange: 'setAcceptChange',
    }),
    ...mapActions({
      //saveFavorites: 'saveFavorites',
    }),
  },
  computed: {
    ...mapState({
      settings: state => state.settings,
      modalType: state => state.modalType,
      updateData: state => state.updateData,
      acceptChange: state => state.acceptChange,
    }),
  },
  watch: {
    local_settings: {
      handler(newValue, oldValue) {
        if(!this.timeout){
          this.setUpdateData(true);
        }
      },
      deep: true
    },
  },
}
</script>

<style>
.modal {
  min-width: 310px;
  min-height: 400px;
  background-color: white;
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.modal_content {
  width: 100%;
}

.modal_content_elem p {
  font-size: 13px;
  color: #7b7b7b;
  font-weight: bold;
}

.modal_content_elem {
  margin-top: 20px;
}

.modal_content_elem select {
  width: 100%;
  margin-top: 5px;
  font-size: 16px;
  padding: 5px;
}

.modal_control {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.modal_control button {
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
}

.background_modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #00000021;
  left: 0;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accept_close {
  position: fixed;
  background-color: white;
  box-shadow: 0px 0px 10px 0px;
  height: 150px;
  width: 250px;
  display: flex;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.accept_close .modal_control {
  display: flex;
  align-items: center;
  justify-content: center;
}
.accept_close .modal_control button {
  margin-left: 5px;
  margin-right: 5px;
}

.modal_close_btn {
  position: absolute;
  right: 0;
  width: 40px;
  top: 0;
  cursor: pointer;
}

.modal_main_info_elem.error span, .modal_main_info_elem.error {
  color: red;
  font-weight: bold;
}
</style>
