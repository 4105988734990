<template>
  <div class="calendar" @click="showMore(null, null); selectFormatMiniModal(false, true)">

    <div class="alert">
      <transition-group name="slide-fade">
        <div v-for="alert in this.globalAlerts" :key="alert.id" :class="`${alert.type}`"
             @click="this.deleteGlobalAlert(alert.id)">
          <span>{{ alert.text }}</span>
        </div>
      </transition-group>
    </div>

    <ModalSettings v-if="this.modalType === 'settings'" v-on:updateCalendar="getCalendar()"></ModalSettings>
    <ModalEditEvent v-if="this.modalType === 'edit'" v-on:deleteEvent="deleteEvent"
                    v-on:saveEvent="saveEvent"
                    v-on:cancelTransfer="cancelTransfer"
    ></ModalEditEvent>
    <ModalAddEvent v-if="this.modalType === 'add'"
                   v-on:cancelTransfer="cancelTransfer"
                   v-on:addEvent="addEvent"></ModalAddEvent>

    <div class="control_panel">
      <div class="select_period">
        <div class="control_panel_format_add">
          <div class="control_panel_format_btn" @click="handlerBtnAddNewEvent()">
            <span class="control_panel_format_btn_pc">Добавить</span>
            <span class="control_panel_format_btn_mobile">+</span>
          </div>
        </div>
        <div v-if="this.settings.calendar_format == 'month'" class="container_select_period_btn">
          <div class="select_period_btn" @click="newMonth.month--">
            <svg class="arrow-left" viewBox="0 0 5 9">
              <path d="M0.419,9.000 L0.003,8.606 L4.164,4.500 L0.003,0.394 L0.419,0.000 L4.997,4.500 L0.419,9.000 Z"/>
            </svg>
          </div>
          <div class="select_period_btn" @click="newMonth.month++">
            <svg class="arrow-right" viewBox="0 0 5 9">
              <path d="M0.419,9.000 L0.003,8.606 L4.164,4.500 L0.003,0.394 L0.419,0.000 L4.997,4.500 L0.419,9.000 Z"/>
            </svg>
          </div>
        </div>

        <div v-if="this.settings.calendar_format == 'week'" class="container_select_period_btn">
          <div class="select_period_btn" @click="changeWeekPeriod('prev')">
            <svg class="arrow-left" viewBox="0 0 5 9">
              <path d="M0.419,9.000 L0.003,8.606 L4.164,4.500 L0.003,0.394 L0.419,0.000 L4.997,4.500 L0.419,9.000 Z"/>
            </svg>
          </div>
          <div class="select_period_btn" @click="changeWeekPeriod('next')">
            <svg class="arrow-right" viewBox="0 0 5 9">
              <path d="M0.419,9.000 L0.003,8.606 L4.164,4.500 L0.003,0.394 L0.419,0.000 L4.997,4.500 L0.419,9.000 Z"/>
            </svg>
          </div>
        </div>

        <div v-if="this.settings.calendar_format == 'day'" class="container_select_period_btn">
          <div class="select_period_btn" @click="changeDayPeriod('prev')">
            <svg class="arrow-left" viewBox="0 0 5 9">
              <path d="M0.419,9.000 L0.003,8.606 L4.164,4.500 L0.003,0.394 L0.419,0.000 L4.997,4.500 L0.419,9.000 Z"/>
            </svg>
          </div>
          <div class="select_period_btn" @click="changeDayPeriod('next')">
            <svg class="arrow-right" viewBox="0 0 5 9">
              <path d="M0.419,9.000 L0.003,8.606 L4.164,4.500 L0.003,0.394 L0.419,0.000 L4.997,4.500 L0.419,9.000 Z"/>
            </svg>
          </div>
        </div>

        <div v-if="this.settings.calendar_format == 'month'">
          <VueDatePicker v-model="newMonth" month-picker/>
        </div>
        <div v-if="this.settings.calendar_format == 'week'">
          <VueDatePicker v-model="format_week.day" :week-start="`${this.settings.first_day}`"
                         :enable-time-picker="false" week-picker/>
        </div>
        <div v-if="this.settings.calendar_format == 'day'">
          <VueDatePicker v-model="format_day" :enable-time-picker="false" day-picker/>
        </div>

        <div class="control_panel_format">
          <div class="control_panel_format_btn" :class="{active: this.settings.calendar_format == 'day'}"
               @click="this.settings.calendar_format = 'day'">Day
          </div>
          <div class="control_panel_format_btn" :class="{active: this.settings.calendar_format == 'week'}"
               @click="this.settings.calendar_format = 'week'">Week
          </div>
          <div class="control_panel_format_btn" :class="{active: this.settings.calendar_format == 'month'}"
               @click="this.settings.calendar_format = 'month'">Month
          </div>
          <div class="control_panel_format_btn" @click="openTodayPeriod()">Today</div>
        </div>
      </div>
      <div class="control_panel_btns">
        <div class="control_panel_settings" @click="this.setModalType('settings')">
          <svg width="48" height="48" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M.974 8.504l1.728-.825a.94.94 0 00.323-1.439l-1.21-1.498a7.009 7.009 0 011.494-1.895l1.727.847a.931.931 0 001.32-.642l.407-1.88a6.96 6.96 0 012.412.001L9.6 3.057a.934.934 0 001.323.637l1.721-.847a7.053 7.053 0 011.511 1.894L12.957 6.24a.942.942 0 00.33 1.437l1.74.826a7.086 7.086 0 01-.529 2.362l-1.914-.012a.935.935 0 00-.912 1.155l.446 1.874a7.002 7.002 0 01-2.17 1.05l-1.194-1.514a.93.93 0 00-1.466.002l-1.18 1.512a7.09 7.09 0 01-2.178-1.05l.43-1.878a.94.94 0 00-.917-1.15l-1.92.011a7.095 7.095 0 01-.06-.149 7.102 7.102 0 01-.488-2.212zM9.96 7.409a2.11 2.11 0 01-1.18 2.74 2.11 2.11 0 01-2.733-1.195 2.11 2.11 0 011.179-2.741A2.11 2.11 0 019.96 7.409z"
                  fill="#000"></path>
          </svg>
        </div>
        <div class="control_panel_menu" @click.stop="selectFormatMiniModal()">
          <div v-if="mini_modal_select_format" class="mini_modal_select_format">
            <div class="control_panel_format_btn" @click="openTodayPeriod()">Today</div>
            <div class="control_panel_format_btn" :class="{active: this.settings.calendar_format == 'day'}"
                 @click="this.settings.calendar_format = 'day'">Day
            </div>
            <div class="control_panel_format_btn" :class="{active: this.settings.calendar_format == 'week'}"
                 @click="this.settings.calendar_format = 'week'">Week
            </div>
            <div class="control_panel_format_btn" :class="{active: this.settings.calendar_format == 'month'}"
                 @click="this.settings.calendar_format = 'month'">Month
            </div>
            <div class="control_panel_format_btn" :class="{active: this.show_waiting_list}"
                 @click="statusMiniMidal()">WaitingList
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="calendar_content">
      <div class="calendar_content_background">

        <div class="calendar_header" v-if="this.settings.calendar_format == 'month'">
          <div :style="`width: ${width}px;`" v-for="d in this.days">{{ d }}</div>
        </div>

        <div class="calendar_header" v-if="this.settings.calendar_format == 'week'">
          <div :style="`width: 50px;`"></div>
          <div :style="`width: ${width}px;`" v-for="(d, index) in this.days">
            <p>{{ d }}</p>
            <p class="calendar_header_date">{{ getDateForFormatWeekHeader(this.format_week.day[0], index, true) }}</p>
          </div>
        </div>

        <div class="calendar_header" v-if="this.settings.calendar_format == 'day'">
          <div class="calendar_header_day">
            {{ day.date }}
          </div>
        </div>


        <div class="calendar_body" v-if="this.settings.calendar_format == 'month'">
          <div v-for="(week, weekIndex) in calendar" class="calendar_body_week">
            <div v-for="(day, dayIndex) in week" class="calendar_body_week_day"
                 @drop="onDrop($event, {week: weekIndex, day: dayIndex, valid: day.valid})" :key="day.num"
                 @dragover.prevent
                 @dragenter.prevent="checkEvent($event, {week: weekIndex, day: dayIndex}); setPreview($event,{week: weekIndex, day: dayIndex, valid: day.valid})"
                 :class="{dayDisabled: dayDisabled === dayIndex && weekDisabled === weekIndex, weekDisabled: weekDisabled === weekIndex, today: day.today, no_valid: !day.valid}"
                 @click="checkDateForValidForNewEvent(day.fullDate, day.valid)">
              <p>{{ day.num }}</p>

              <div v-for="(event, index) in day.events.slice(0, 5)" class="calendar_body_week_day_event"
                   :style="`width: ${(event.duration * width) - 6}px; margin-top: ${event.string * 20}px; background-color: ${event.color};`"
                   :class="{clone: event.clone, preview: event.preview}"
                   :key="`event-${event.ID}`"
                   @dragenter.prevent="checkEvent($event, {week: weekIndex})"
                   @click.stop="this.setEditEvent(event); this.setModalType('edit'); showMore(null, null)"
              >
                <div v-if="!event.continue && !event.repeat" class="calendar_body_week_day_event_resize left"
                     @dragstart="onDragStart($event, event.ID, true, 'left', {week: weekIndex, day: dayIndex, index: index})"
                     draggable="true"
                     @dragenter.prevent></div>
                <div class="calendar_body_week_day_event_content" :style="`color: ${event.text_color};`"
                     @dragstart="onDragStart($event, event.ID, false, false, {week: weekIndex, day: dayIndex, index: index})"
                     draggable="true"
                     @dragenter.prevent
                     :class="{all_day: event.all_day}"
                >
                  <p class="calendar_body_week_day_event_time_start" v-if="!event.all_day">{{
                      formatTimeFromSettings(event.dateFrom, event.time_zone)
                    }}</p>
                  <p class="calendar_body_week_day_event_text">{{ event.text }}</p>
                  <p v-if="event.duration > 2 && !event.all_day" class="calendar_body_week_day_event_time_end">{{
                      formatTimeFromSettings(event.dateTo, event.time_zone)
                    }}</p>
                </div>
                <div v-if="event.lastWeek && !event.repeat" class="calendar_body_week_day_event_resize right"
                     @dragstart="onDragStart($event, event.ID, true, 'right', {week: weekIndex, day: dayIndex, index: index})"
                     draggable="true"
                     @dragenter.prevent></div>
              </div>

              <div v-if="day.events.length > 5" class="show_more" :style="`width: ${width + 1}px;`"
                   @click.stop="showMore(weekIndex, dayIndex)">+{{ day.events.length - 5 }} more
              </div>

              <div v-if="showMoreDay.week === weekIndex && showMoreDay.day === dayIndex"
                   class="calendar_body_week_day_more"
                   :style="`width: ${width + 1}px;`">

                <div @click.stop="" class="calendar_body_week_day_more_header">
                  <span>{{ defaultDaysOfWeek[(new Date(day.fullDate)).getDay()] }}</span>
                  <div>
                    <span>{{ day.events.length }}</span>
                    <svg @click="showMore(null, null)" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                        <path d="M16 8L8 16M8.00001 8L16 16" stroke="#000000" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                      </g>
                    </svg>
                  </div>
                </div>

                <div v-for="(event, index) in day.events" class="calendar_body_week_day_event in_modal"
                     :style="`background-color: ${event.color};`"
                     :key="`event-${event.ID}`"
                     @dragenter.prevent="checkEvent($event, {week: weekIndex})"
                     @click.stop="this.setEditEvent(event); this.setModalType('edit');"
                >
                  <div class="calendar_body_week_day_event_content" :style="`color: ${event.text_color};`"
                       @dragstart="onDragStart($event, event.ID, false, false, {week: weekIndex, day: dayIndex, index: index})"
                       draggable="true"
                       @dragenter.prevent
                  >
                    <p class="calendar_body_week_day_event_text">{{ event.text }}</p>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>

        <div class="calendar_body format_week" v-if="this.settings.calendar_format == 'week'">
          <div class="calendar_body_time_col">
            <div v-for="time in getCountSlotsInDay(this.settings.start_time, this.settings.end_time)"
                 class="calendar_body_time_col_elem">
              {{ getTimeCalendar(time) }}
            </div>
          </div>
          <div v-for="(day, dayIndex) in week" class="calendar_body_week"
               :class="{dayDisabled: dayDisabled}"
          >
            <div v-for="(slot, slotIndex) in day.slots" class="calendar_body_week_slot"
                 @drop="onDropWeek($event, {day: dayIndex, slot: slotIndex, valid: slot.valid})" :key="day.num"
                 @dragover.prevent
                 @dragenter.prevent="checkEventWeek($event, {day: dayIndex, slot: slotIndex}); setPreviewWeek($event,{day: dayIndex, slot: slotIndex, valid: slot.valid})"
                 @click="checkDateForValidForNewEvent(slot.fullDate, slot.valid)"
                 :class="{dayDisabled: slotDisabled == slotIndex}"
            >
              <div v-for="(event, indexEvent) in slot.events" class="calendar_body_week_day_event"

                   :style="`width: ${event.width - 6}%;
                   left: ${event.col}%;

                   background-color: ${event.color}; height: ${event.height * 50 + event.height}px`"
                   :class="{clone: event.clone, preview: event.preview}"
                   @click.stop="this.setEditEvent(event); this.setModalType('edit');">

                <div class="calendar_body_week_day_event_resize_week top"
                     @dragstart="onDragStartWeek($event, event.ID, true, 'top', {day: dayIndex, slot: slotIndex, indexEvent: indexEvent})"
                     draggable="true"
                     @dragenter.prevent
                ></div>
                <div class="calendar_body_week_day_event_content" :style="`color: ${event.text_color};`"
                     @dragstart="onDragStartWeek($event, event.ID, false, false, {day: dayIndex, slot: slotIndex, indexEvent: indexEvent})"
                     draggable="true"
                     @dragenter.prevent
                >
                  <p>{{ event.text }}</p>
                  <p>{{ formatTimeFromSettings(event.dateFrom, event.time_zone) }} -
                    {{ formatTimeFromSettings(event.dateTo, event.time_zone) }}</p>
                </div>
                <div class="calendar_body_week_day_event_resize_week bottom"
                     @dragstart="onDragStartWeek($event, event.ID, true, 'bottom', {day: dayIndex, slot: slotIndex, indexEvent: indexEvent})"
                     draggable="true"
                     @dragenter.prevent
                ></div>
              </div>

            </div>
          </div>
        </div>


        <div class="calendar_body format_day" v-if="this.settings.calendar_format == 'day'">

          <div class="calendar_body_time_col">
            <div v-for="time in getCountSlotsInDay(this.settings.start_time, this.settings.end_time)"
                 class="calendar_body_time_col_elem">
              {{ getTimeCalendar(time) }}
            </div>
          </div>

          <div class="calendar_body_week">
            <div v-for="(slot, slotIndex) in day.slots" class="calendar_body_week_slot"
                 @drop="onDropDay($event, {day: null, slot: slotIndex, valid: slot.valid})" :key="day.num"
                 @dragover.prevent
                 @dragenter.prevent="checkEventWeek($event, {day: null, slot: slotIndex}); setPreviewDay($event,{day: null, slot: slotIndex, valid: slot.valid})"
                 @click="checkDateForValidForNewEvent(slot.fullDate, slot.valid)"
                 :class="{dayDisabled: slotDisabled}"
            >

              <div v-for="(event, indexEvent) in slot.events" class="calendar_body_week_day_event"
                   :style="`width: ${event.width - 6}%;
                   left: ${event.col}%;
                   background-color: ${event.color}; height: ${event.height * 50 + event.height}px`"
                   :class="{clone: event.clone, preview: event.preview}"
                   @click.stop="this.setEditEvent(event); this.setModalType('edit');">

                <div class="calendar_body_week_day_event_resize_week top"
                     @dragstart="onDragStartDay($event, event.ID, true, 'top', {day: null, slot: slotIndex, indexEvent: indexEvent})"
                     draggable="true"
                     @dragenter.prevent
                ></div>
                <div class="calendar_body_week_day_event_content" :style="`color: ${event.text_color};`"
                     @dragstart="onDragStartDay($event, event.ID, false, false, {day: null, slot: slotIndex, indexEvent: indexEvent})"
                     draggable="true"
                     @dragenter.prevent
                >
                  <p>{{ event.text }}</p>
                  <p>{{ formatTimeFromSettings(event.dateFrom, event.time_zone) }} -
                    {{ formatTimeFromSettings(event.dateTo, event.time_zone) }}</p>
                </div>
                <div class="calendar_body_week_day_event_resize_week bottom"
                     @dragstart="onDragStartDay($event, event.ID, true, 'bottom', {day: null, slot: slotIndex, indexEvent: indexEvent})"
                     draggable="true"
                     @dragenter.prevent
                ></div>
              </div>

            </div>
          </div>
        </div>


      </div>


      <div class="calendar_content_waiting_list" :class="{show_waiting_list: show_waiting_list}">
        <div class="waiting_list"
             @drop="onDropWaitingList($event)"
             @dragover.prevent
             @dragenter.prevent="setPreviewWaitingList($event)"
        >
          <div class="waiting_header">
            <h3>Лист ожидания</h3>
            <div class="control_panel_format_btn" @click="handlerBtnAddNewEvent(true)">+</div>
          </div>

          <div class="waiting_list_elem" v-for="(waiting_event, index) in waiting_events"
               @dragstart="onDragStart($event, waiting_event.ID, false, false, null)"
               @touchstart="onDragStart($event, waiting_event.ID, false, false, null)"
               draggable="true"
               @dragenter.prevent
               :class="{preview: waiting_event.preview}"
          >
            <div class="waiting_list_elem_content">
              <p>{{ waiting_event.user_login }}</p>
              <p>{{ this.getDateInterval(waiting_event.dateFrom, waiting_event.dateTo, waiting_event.preview) }}</p>

              <p>{{ waiting_event.event_group_name }}</p>
            </div>
            <div class="waiting_list_elem_drag_img">
              <img src="@/assets/burger.svg" alt="">
            </div>
          </div>

          <div v-if="!waiting_events.length" class="waiting_list_elem" style="opacity: .5">Перетащите сюда для
            добавления события
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import axios from 'axios';

import ModalSettings from "@/components/ModalSettings.vue";
import ModalEditEvent from "@/components/ModalEditEvent.vue";
import ModalAddEvent from "@/components/ModalAddEvent.vue";
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  components: {
    VueDatePicker,
    ModalSettings,
    ModalEditEvent,
    ModalAddEvent,
  },
  data() {
    return {
      mini_modal_select_format: false,
      show_waiting_list: false,
      loading: true,
      waiting_events: {},

      deleteLastHalfSlot: false,

      format_week: {
        day: [],
      },
      format_day: new Date(),

      timezone: null,
      format: null,

      userTimeZone: null,
      width: 0,

      showMoreDay: {
        week: null,
        day: null
      },

      dateEventFrom: new Date(),
      timeEventFrom: {
        time: {
          hours: new Date().getHours(),
          minutes: new Date().getMinutes(),
        }
      },

      dateEventTo: new Date(),
      timeEventTo: {
        time: {
          hours: new Date().getHours(),
          minutes: new Date().getMinutes(),
        }
      },

      calendar: null,
      week: null,
      day: null,
      datePicker: null,

      today: new Date(),
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
      newMonth: {
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      },

      transferEvent: null,
      dragActivate: false,
      dayDisabled: null,
      slotDisabled: null,
      weekDisabled: null,

      date: new Date(),

      defaultDaysOfWeek: [
        'Sun',
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri',
        'Sat'
      ],
      monthes: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],

      events: {},

      defaultValuesPeriodMonth: null,
      defaultValuesPeriodWeek: null,
    }
  },
  created() {
    window.addEventListener('resize', this.updateWidth);
  },
  methods: {
    async reloadCalendarAfterEditDate() {
      await this.getEvents()
          .then(res => {
            this.getWaitingList()
                .then(res => {
                  if (res) {
                    for (let event_waiting in res) {
                      res[event_waiting].dateFrom = (new Date(res[event_waiting].dateFrom * 1000)).toString();
                      res[event_waiting].dateTo = (new Date(res[event_waiting].dateTo * 1000)).toString();

                      if (res[event_waiting].repeat_end != 0) {
                        res[event_waiting].repeat_end = (new Date(res[event_waiting].repeat_end * 1000)).toString();
                      }

                    }
                    this.waiting_events = res;
                  } else {
                    this.waiting_events = [];
                  }
                })
            for (let event in res) {
              res[event].dateFrom = (new Date(res[event].dateFrom * 1000)).toString();
              res[event].dateTo = (new Date(res[event].dateTo * 1000)).toString();

              if (res[event].repeat_end != 0) {
                res[event].repeat_end = (new Date(res[event].repeat_end * 1000)).toString();
              }

            }
            return res;
          })
          .then(res => {
            this.events = res;
          })
          .then(res => {
            this.setEventDefaultOptions();
            this.loadEventsInCalendar();
          })
    },
    addAlert(text) {
      let alertID = Math.floor(Math.random() * 1000);
      this.setGlobalAlerts({type: 'error', text: text, id: alertID});
      setTimeout(() => {
        this.deleteGlobalAlert(alertID);
      }, "5000");
    },
    handlerBtnAddNewEvent(waitinglist = false) {
      this.setDateForNewEvent(new Date())
      this.setModalType('add');
      this.setItsWaitingEvent(waitinglist);
    },
    checkDateForValidForNewEvent(date, valid) {
      if (valid) {
        this.setForEventSelectedDate(date);
        this.setModalType('add');
      } else {
        this.addAlert('Нельзя добавить событие в прошедший день');
        return false;
      }
    },
    setForEventSelectedDate(date) {
      this.setDateForNewEvent(date);
    },
    openTodayPeriod() {
      if (this.settings.calendar_format == 'month') {
        this.newMonth.month = new Date().getMonth();
        this.newMonth.year = new Date().getFullYear();
      } else if (this.settings.calendar_format == 'week') {
        this.format_week.day = this.defaultValuesPeriodWeek;
      } else if (this.settings.calendar_format == 'day') {
        this.format_day = new Date();
      }
    },
    statusMiniMidal() {
      if (this.show_waiting_list) {
        this.show_waiting_list = false;
      } else {
        this.show_waiting_list = true;
      }
    },
    selectFormatMiniModal(bool, auto = false) {
      if(auto){
        this.mini_modal_select_format = bool
      }else{
        if(this.mini_modal_select_format){
          this.mini_modal_select_format = false
        }else{
          this.mini_modal_select_format = true;
        }
      }

    },
    getMarginLeftForEventInSlots(lengthArray, indexEvent, max_length) { // Отступ слева в зависимости от кол-ва ивентов в слоте
      if (indexEvent == 0) return 0;
      if (indexEvent == max_length) return (100 / Number(lengthArray)) * Number(indexEvent);
      return (100 / Number(lengthArray)) * Number(indexEvent);
    },
    getDateForFormatWeekHeader(date, index, format = false) { // Функиця для получения даты в шапке при формате календаря Week
      date = new Date(date);
      date.setDate(date.getDate() + index);

      let dateForFormat = date.toLocaleDateString();

      if (format) {
        return dateForFormat.substring(0, 5);
      } else {
        return date;
      }
    },
    async changeWeekPeriod(action) { // Функция для смены периода календаря при формате Week
      let newWeekFirstDay = new Date(this.format_week.day[0]);
      let newWeekSevenDay = new Date(this.format_week.day[1]);
      if (action === 'next') {
        newWeekFirstDay.setDate(newWeekFirstDay.getDate() + 7);
        newWeekSevenDay.setDate(newWeekSevenDay.getDate() + 7);
      } else if (action === 'prev') {
        newWeekFirstDay.setDate(newWeekFirstDay.getDate() - 7);
        newWeekSevenDay.setDate(newWeekSevenDay.getDate() - 7);
      }

      this.format_week.day = [new Date(newWeekFirstDay), new Date(newWeekSevenDay)];
    },

    async changeDayPeriod(action) {

      let newDateDay = new Date(this.format_day);
      if (action === 'next') {
        newDateDay.setDate(newDateDay.getDate() + 1);
      } else if (action === 'prev') {
        newDateDay.setDate(newDateDay.getDate() - 1);
      }

      this.format_day = new Date(newDateDay);
    },

    getTimeCalendar(count, slot = false, secondSlot = false) {
      if (slot) {
        let timeStart = this.settings.start_time.split(':');
        let hour = timeStart[0];
        let min = timeStart[1];

        min = this.settings.slot_duration * (count - 1);

        if (secondSlot) {
          min = min + (this.settings.slot_duration / 2);
        } else {

        }

        while (min > 59) {
          min -= 60;
          hour++;
        }

        if (hour < 10 && String(hour).length < 2) hour = "0" + hour;
        if (min < 10 && String(min).length < 2) min = "0" + min;

        return hour + ":" + min;


      } else {

        let timeStart = this.settings.start_time.split(':');
        let hour = timeStart[0];
        let min = timeStart[1];

        min = this.settings.slot_duration * (count - 1);

        while (min > 59) {
          min -= 60;
          hour++;
        }

        if (this.settings.time_format == 12) {
          let hoursTo = hour;
          let minutesTo = min;
          let ampmTo = hoursTo >= 12 ? 'PM' : 'AM';
          hoursTo = hoursTo % 12;
          hoursTo = hoursTo ? hoursTo : 12; // 0 = 12
          minutesTo = minutesTo < 10 ? '0' + minutesTo : minutesTo;
          let timeTo = hoursTo + ':' + minutesTo + ' ' + ampmTo;

          return timeTo;
        } else {

          if (hour < 10 && String(hour).length < 2) hour = "0" + hour;
          if (min < 10) min = "0" + min;

          return hour + ":" + min;
        }
      }

    },

    async reloadAllCalendar() {
      this.setModalType(null);
      await this.clearCalendarEvenst();
      await this.getEvents()
          .then(res => {
            this.getWaitingList()
                .then(res => {
                  if (res) {
                    for (let event_waiting in res) {
                      res[event_waiting].dateFrom = (new Date(res[event_waiting].dateFrom * 1000)).toString();
                      res[event_waiting].dateTo = (new Date(res[event_waiting].dateTo * 1000)).toString();

                      if (res[event_waiting].repeat_end != 0) {
                        res[event_waiting].repeat_end = (new Date(res[event_waiting].repeat_end * 1000)).toString();
                      }

                    }
                    this.waiting_events = res;
                  } else {
                    this.waiting_events = [];
                  }
                })
            for (let event in res) {
              res[event].dateFrom = (new Date(res[event].dateFrom * 1000)).toString();
              res[event].dateTo = (new Date(res[event].dateTo * 1000)).toString();

              if (res[event].repeat_end != 0) {
                res[event].repeat_end = (new Date(res[event].repeat_end * 1000)).toString();
              }

            }
            return res;
          })
          .then(res => {
            this.events = res;
          })
          .then(res => {
            this.setEventDefaultOptions();
            this.loadEventsInCalendar();
          })
    },
    cancelTransfer(eventID, waiting_list) {

      if (waiting_list) {
        for (let i in this.waiting_events) {
          if (this.waiting_events[i].ID == eventID) {
            this.waiting_events[i].preview = false;
            break;
          }
        }
      }
      this.setModalType(null);
      this.onDropWaitingList('cancel');

    },
    setPreviewWaitingList(e) {
      if (this.transferEvent.event === 'transferWaitingList') {
        for (let i in this.waiting_events) {
          if (this.waiting_events[i].ID == this.transferEvent.eventIndex) {
            this.waiting_events[i].preview = true;
            break;
          }
        }
      } else {
        if (this.waiting_events.length) {
          for (let i in this.waiting_events) {
            if (this.waiting_events[i].ID == this.events[this.transferEvent.eventIndex].ID && this.waiting_events[i].color == this.events[this.transferEvent.eventIndex].color) {
              this.waiting_events.splice(i, 1);
              break;
            }
          }
        }


        if (!this.waiting_events.length) {
          this.waiting_events[0] = this.events[this.transferEvent.eventIndex];
        } else {
          this.events[this.transferEvent.eventIndex].preview = true;
          this.waiting_events.push(this.events[this.transferEvent.eventIndex])
        }

      }


    },
    onDropWaitingList(e) {
      if (this.transferEvent.event == 'transferWaitingList' || e === 'cancel') {
        for (let i in this.waiting_events) {
          this.waiting_events[i].preview = false;
        }
      } else {

        this.moveEventToWaitingList(this.events[this.transferEvent.eventIndex].ID);
      }
      this.dayDisabled = null;
      this.weekDisabled = null;
      this.slotDisabled = null;
    },


    async moveEventToWaitingList(eventID) {

      try {
        const response = await axios.post(
            'property.php?moveEventToWaitingList',
            {
              eventID: eventID,
              headers: {
                'Content-Type': 'application/json',
              },
            },
        )

        if (response.data.status !== 'success') {
        } else {
          this.reloadAllCalendar();
        }
      } catch (e) {
        console.log(e);
        return false;
      }
    },


    getUserTimeZone() {
      let offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
      //return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
      return (offset < 0 ? "+" : "-") + (Math.floor(o / 60));
    },
    getDurationWithSettings(dayNum) {
      let firstDay = Number(this.settings.first_day) + 7;
      let dayOfWeek = (dayNum == 0) ? 7 : dayNum;
      return firstDay - dayOfWeek;
    },
    getDayOfWeekWithSettings(dayNum) {
      let eventDayNum = (dayNum == 0) ? 7 : dayNum;
      if (this.settings.first_day == 1) {
        return eventDayNum;
      } else {
        let searchDay;
        if (eventDayNum == 1) {
          searchDay = 'Mon';
        } else if (eventDayNum == 2) {
          searchDay = 'Tue';
        } else if (eventDayNum == 3) {
          searchDay = 'Wed';
        } else if (eventDayNum == 4) {
          searchDay = 'Thu';
        } else if (eventDayNum == 5) {
          searchDay = 'Fri';
        } else if (eventDayNum == 6) {
          searchDay = 'Sat';
        } else if (eventDayNum == 7) {
          searchDay = 'Sun';
        }
        for (let i in this.days) {
          if (this.days[i] == searchDay) {
            return (Number(i) + 1);
          }
        }
      }
    },
    getTimeEvent(from) {
      let date = new Date(from);
      let hours;
      let min;
      if (Number(date.getHours()) < 10) {
        hours = "0" + date.getHours();
      } else {
        hours = date.getHours();
      }
      if (Number(date.getMinutes()) < 10) {
        min = "0" + date.getMinutes();
      } else {
        min = date.getMinutes();
      }
      return hours + ":" + min;
    },
    getDiff(a, b) {
      if (Math.sign(a) === Math.sign(b)) {
        return Math.abs(a - b);
      } else {
        return Math.abs(a) + Math.abs(b);
      }
    },
    formatTimeFromSettings(date, timezone) {
      let dateEvent = new Date(date)
      date = new Date(date);
      if (this.settings.time_format == 12) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // 0 = 12
        minutes = minutes < 10 ? '0' + minutes : minutes;
        return hours + ':' + minutes + ' ' + ampm;
      } else {
        return this.getTimeEvent(date);
      }

    },
    listenEsc() {
      if (event.code == "Escape") {
        if (this.updateData) {
          this.setAcceptChange(true);
        } else {
          this.setModalType(null);
        }
      }
    },
    ...mapMutations({
      setModalType: 'setModalType',
      setAcceptChange: 'setAcceptChange',
      setDays: 'setDays',
      setEditEvent: 'setEditEvent',
      setDateForNewEvent: 'setDateForNewEvent',
      setItsWaitingEvent: 'setItsWaitingEvent',
      deleteGlobalAlert: 'deleteGlobalAlert',
      setGlobalAlerts: 'setGlobalAlerts',
    }),
    showMore(week, day) {
      this.showMoreDay.week = week;
      this.showMoreDay.day = day;
    },
    updateWidth() {
      let element;
      if (this.settings.calendar_format == 'month') {
        element = document.querySelector('.calendar_body_week_day');
      } else {
        element = document.querySelector('.calendar_body_week_slot');
      }
      this.width = element.offsetWidth;
    },
    async saveNewDateEvent(eventID, dateFrom, dateTo) {
      try {
        const response = await axios.post(
            'property.php?saveNewDateEvent',
            {
              eventID: eventID,
              dateFrom: new Date(dateFrom),
              dateTo: new Date(dateTo),
              headers: {
                'Content-Type': 'application/json',
              },
            },
        )

        if (response.data.status !== 'success') {
          console.log('Ошибка');
        }
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    deleteAllRepeat() {
      for (let i in this.events) {
        if (this.events[i].repeat) {
          this.events.splice(i, 1);
        }
      }
    },
    async clearCalendarEvenst() {

      for (let week in this.calendar) {
        for (let day in this.calendar[week]) {
          this.calendar[week][day].events = [];
        }
      }
      for (let dayOfWeek in this.week) {
        for (let slot in this.week[dayOfWeek]) {
          for (let clear in this.week[dayOfWeek].slots) {
            this.week[dayOfWeek].slots[clear].events = [];
          }
        }
      }

      for (let clearDay in this.day.slots) {
        this.day.slots[clearDay].events = [];
      }


    },
    async addEvent(eventData) {


      let url;
      let data = new FormData();
      for (let i = 0; i < eventData.uploadFiles.length; i++) {
        data.append('file[' + i + ']', eventData.uploadFiles[i]);
      }
      data.append('event', JSON.stringify(eventData));

      if (this.itsWaitingEvent) {
        url = 'property.php?addEvent&waiting_list';
      } else {
        url = 'property.php?addEvent';
      }

      try {
        const response = await axios.post(
            url,
            data,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            },
        )

        if (response.data.status === 'success') {
          this.reloadAllCalendar();
        }

      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async saveEvent(eventID, waiting_list = false) {
      if (this.editEvent.repeat_end != 0) {
        this.editEvent.repeat_end = new Date(this.editEvent.repeat_end)
      }

      let url;
      let data = new FormData();
      for (let i = 0; i < this.editEvent.uploadFiles.length; i++) {
        data.append('file[' + i + ']', this.editEvent.uploadFiles[i]);
      }
      data.append('event', JSON.stringify(this.editEvent));

      if (!waiting_list) {
        url = 'property.php?saveEvent';
      } else {
        url = 'property.php?moveEventFromWaitingList';
      }

      try {
        const response = await axios.post(
            url,
            data,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            },
        )
        if (response.data.status === 'success') {
          this.reloadAllCalendar()
        }

      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async deleteEvent(eventID, waiting_list) {

      let url;
      if (waiting_list) {
        url = 'property.php?deleteEventWaitingList';
      } else {
        url = 'property.php?deleteEvent';
      }

      try {
        const response = await axios.post(
            url,
            {
              eventID: eventID,
              headers: {
                'Content-Type': 'application/json',
              },
            },
        )


        if (response.data.status === 'success') {
          this.setModalType(null);
          if (waiting_list) {
            for (let i in this.waiting_events) {
              if (this.waiting_events[i].ID === eventID) {
                this.waiting_events.splice(i, 1);
              }
            }
          } else {
            for (let i in this.events) {
              if (this.events[i].ID === eventID) {
                this.events.splice(i, 1);
              }
            }
            this.clearCalendarEvenst()
                .then(res => {
                  this.setEventDefaultOptions();
                  this.loadEventsInCalendar();
                })
          }

        }
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    checkEvent(e, obj) {
      this.dayDisabled = obj.day;
      this.weekDisabled = obj.week;
    },
    checkEventWeek(e, obj) {
      this.dayDisabled = obj.day;
      this.slotDisabled = obj.slot;
    },
    getFormatDate(value) {
      return new Date(value).toISOString().substr(0, 19);
    },
    setPreviewDay(e, obj) {
      if (!obj.valid) {
        return false;
      }

      let copyEventPreviewClone = Object.assign({}, this.events[this.transferEvent.eventIndex]); // Копируем основное событие для передачи дальше
      copyEventPreviewClone.preview = true;

      if (this.transferEvent.event === 'resize') {

        if (this.transferEvent.side === 'top') {
          let timeSlotToResize = (new Date(this.day.slots[obj.slot].fullDate)).getTime(); // Время слота (15:30)
          let timeEventFrom = (new Date(this.events[Number(this.transferEvent.eventIndex)].dateFrom)).getTime(); // Получаем следующий слот в миллисекундах
          let timeEventTo = (new Date(this.events[Number(this.transferEvent.eventIndex)].dateTo)).getTime(); // Получаем следующий слот в миллисекундах
          if (timeSlotToResize <= timeEventTo) {
            for (let slot in this.day.slots) {
              for (let event in this.day.slots[slot].events) {
                if (this.day.slots[slot].events[event].preview || this.day.slots[slot].events[event].ID == copyEventPreviewClone.ID) {
                  this.day.slots[slot].events.splice(event, 1);
                }
              }
            }
            let searchEventEndSlotIndex = obj.slot;
            while (searchEventEndSlotIndex != (Number(this.day.slots.length) + 1)) { // Пока индекс поиска не будет равен длинне масива слотов + 1, чтобы добавить в последний тоже
              // Получаем время слота для сравнения  с концом Event
              let timeSlotForSearchEventEnd = (new Date(this.day.slots[searchEventEndSlotIndex].fullDate)).getTime();
              let mil_seconds = this.settings.slot_duration / 2;
              let timeSlotForSearchEventEndNext = (new Date(this.day.slots[searchEventEndSlotIndex].fullDate)).getTime() + mil_seconds * 60000;

              if ((timeSlotForSearchEventEnd < timeEventTo || timeSlotForSearchEventEnd == timeEventTo) && (timeEventTo < timeSlotForSearchEventEndNext)) {
                copyEventPreviewClone.height = searchEventEndSlotIndex - Number(obj.slot) + 1;
                break;
              }
              searchEventEndSlotIndex++;
            }
            this.day.slots[obj.slot].events.unshift(copyEventPreviewClone);

          }
        } else if (this.transferEvent.side === 'bottom') {
          let timeSlotToResize = (new Date(this.day.slots[obj.slot].fullDate)).getTime(); // Время слота (15:30)
          let timeEventFrom = (new Date(this.events[Number(this.transferEvent.eventIndex)].dateFrom)).getTime(); // Получаем следующий слот в миллисекундах
          let timeEventTo = (new Date(this.events[Number(this.transferEvent.eventIndex)].dateTo)).getTime(); // Получаем следующий слот в миллисекундах
          if (timeEventFrom <= timeSlotToResize) {
            let lastSlotIndexStartEvent;
            for (let slot in this.day.slots) {
              for (let event in this.day.slots[slot].events) {
                if (this.day.slots[slot].events[event].preview || this.day.slots[slot].events[event].ID == copyEventPreviewClone.ID) {
                  lastSlotIndexStartEvent = slot;
                  this.day.slots[slot].events[event].preview = true;
                  this.day.slots[slot].events[event].height = (Number(obj.slot) - Number(lastSlotIndexStartEvent) + 1);
                }
              }
            }
          }
        }

      } else if (this.transferEvent.event !== 'transferWaitingList') {

        for (let slot in this.day.slots) {
          for (let event in this.day.slots[slot].events) {
            if (this.day.slots[slot].events[event].preview || this.day.slots[slot].events[event].ID == copyEventPreviewClone.ID) {
              this.day.slots[slot].events.splice(event, 1);
            }
          }
        }

        this.day.slots[obj.slot].events.unshift(copyEventPreviewClone);
      }
    },
    setPreviewWeek(e, obj) {

      if (!obj.valid) return false;

      let copyEventPreviewClone = Object.assign({}, this.events[this.transferEvent.eventIndex]); // Копируем основное событие для передачи дальше
      copyEventPreviewClone.preview = true;

      if (this.transferEvent.event === 'resize') {

        if (this.transferEvent.side === 'top') {
          let timeSlotToResize = (new Date(this.week[obj.day].slots[obj.slot].fullDate)).getTime(); // Время слота (15:30)
          let timeEventFrom = (new Date(this.events[Number(this.transferEvent.eventIndex)].dateFrom)).getTime(); // Получаем следующий слот в миллисекундах
          let timeEventTo = (new Date(this.events[Number(this.transferEvent.eventIndex)].dateTo)).getTime(); // Получаем следующий слот в миллисекундах
          if (timeSlotToResize <= timeEventTo) {
            for (let slot in this.week[obj.day].slots) {
              for (let event in this.week[obj.day].slots[slot].events) {
                if (this.week[obj.day].slots[slot].events[event].preview || this.week[obj.day].slots[slot].events[event].ID == copyEventPreviewClone.ID) {
                  this.week[obj.day].slots[slot].events.splice(event, 1);
                }
              }
            }
            let searchEventEndSlotIndex = obj.slot;
            while (searchEventEndSlotIndex != (Number(this.week[obj.day].slots.length) + 1)) { // Пока индекс поиска не будет равен длинне масива слотов + 1, чтобы добавить в последний тоже
              // Получаем время слота для сравнения  с концом Event
              let timeSlotForSearchEventEnd = (new Date(this.week[obj.day].slots[searchEventEndSlotIndex].fullDate)).getTime();
              let mil_seconds = this.settings.slot_duration / 2;
              let timeSlotForSearchEventEndNext = (new Date(this.week[obj.day].slots[searchEventEndSlotIndex].fullDate)).getTime() + mil_seconds * 60000;

              if ((timeSlotForSearchEventEnd < timeEventTo || timeSlotForSearchEventEnd == timeEventTo) && (timeEventTo < timeSlotForSearchEventEndNext)) {
                copyEventPreviewClone.height = searchEventEndSlotIndex - Number(obj.slot) + 1;
                break;
              }
              searchEventEndSlotIndex++;
            }
            this.week[obj.day].slots[obj.slot].events.unshift(copyEventPreviewClone);

          }
        } else if (this.transferEvent.side === 'bottom') {
          let timeSlotToResize = (new Date(this.week[obj.day].slots[obj.slot].fullDate)).getTime(); // Время слота (15:30)
          let timeEventFrom = (new Date(this.events[Number(this.transferEvent.eventIndex)].dateFrom)).getTime(); // Получаем следующий слот в миллисекундах
          let timeEventTo = (new Date(this.events[Number(this.transferEvent.eventIndex)].dateTo)).getTime(); // Получаем следующий слот в миллисекундах
          if (timeEventFrom <= timeSlotToResize) {
            let lastSlotIndexStartEvent;
            for (let slot in this.week[obj.day].slots) {
              for (let event in this.week[obj.day].slots[slot].events) {
                if (this.week[obj.day].slots[slot].events[event].preview || this.week[obj.day].slots[slot].events[event].ID == copyEventPreviewClone.ID) {
                  lastSlotIndexStartEvent = slot;
                  this.week[obj.day].slots[slot].events[event].preview = true;
                  this.week[obj.day].slots[slot].events[event].height = (Number(obj.slot) - Number(lastSlotIndexStartEvent) + 1);
                }
              }
            }
          }
        }

      } else if (this.transferEvent.event !== 'transferWaitingList') {
        for (let day in this.week) {
          for (let slot in this.week[day].slots) {
            for (let event in this.week[day].slots[slot].events) {
              if (this.week[day].slots[slot].events[event].preview || this.week[day].slots[slot].events[event].ID == copyEventPreviewClone.ID) {
                this.week[day].slots[slot].events.splice(event, 1);
              }
            }
          }
        }
        this.week[obj.day].slots[obj.slot].events.unshift(copyEventPreviewClone);
      }


    },

    async setPreview(e, obj) {

      if (!obj.valid) return false;

      let copyEventPreviewClone = Object.assign({}, this.events[this.transferEvent.eventIndex]); // Копируем основное событие для передачи дальше
      copyEventPreviewClone.preview = true;

      this.deleteAllRepeat();

      if (this.transferEvent.event === 'resize') {

        if (this.transferEvent.side === 'left') {
          if (!this.dateCompare(new Date(this.calendar[obj.week][obj.day].fullDate), '>', new Date(this.events[this.transferEvent.eventIndex].dateTo))) {

            for (let week in this.calendar) {
              for (let day in this.calendar[week]) {
                for (let event in this.calendar[week][day].events) {
                  if (this.calendar[week][day].events[event].preview || this.calendar[week][day].events[event].ID == copyEventPreviewClone.ID) {
                    this.calendar[week][day].events.splice(event, 1);
                  }
                }
              }
            }

            let timeFrom = this.getTimeOfDate(copyEventPreviewClone.dateFrom);
            let dateFrom = new Date(this.calendar[obj.week][obj.day].fullDate);
            dateFrom.setHours(timeFrom.hour, timeFrom.min, timeFrom.sec, 0);
            copyEventPreviewClone.dateFrom = dateFrom;
            copyEventPreviewClone.duration = this.getDuration(dateFrom, copyEventPreviewClone.dateTo);

            // СДЕЛАНО
            let duration = this.getDuration(copyEventPreviewClone.dateFrom, copyEventPreviewClone.dateTo);
            let dayOfWeek = this.getDayOfWeekWithSettings(new Date(this.calendar[this.weekDisabled][this.dayDisabled].fullDate).getDay()); // Получаем день недели event в зависимости от первого дня недели
            let countDuration = dayOfWeek + duration; // Счётчик длительности для определия недели. Изначально == День недели события + Длительность
            let countWeek = 1; // Счётчик недель, которые будем прибавлять

            if (countDuration > 8) { // Если событие не помещается в одну неделю, то
              copyEventPreviewClone.lastWeek = false;
              let leftDays = this.getRestDaysOnNextWeek(duration, dayOfWeek); // Осталось дней
              while (leftDays > 0) { // Если счётчик длительности больше 7, то надо перенести событие на следующую неделю

                if (!this.calendar[(Number(this.weekDisabled) + countWeek)]) break;

                let copyEvent = Object.assign({}, copyEventPreviewClone); // Копируем основное событие для передачи дальше
                copyEvent.continue = true;

                if (leftDays > 7) {
                  copyEvent.duration = 7;
                } else {
                  copyEvent.duration = leftDays;
                  copyEvent.lastWeek = true;
                }
                this.calendar[Number(this.weekDisabled) + countWeek][0].events.unshift(copyEvent);
                leftDays = Number(leftDays) - 7;
                countWeek++;
              }
            } else {
              copyEventPreviewClone.duration = duration;
              copyEventPreviewClone.lastWeek = true;
            }
            if (duration === 1) {
              copyEventPreviewClone.lastWeek = true;
            }

            this.calendar[this.weekDisabled][this.dayDisabled].events.unshift(copyEventPreviewClone);


          }
        } else if (this.transferEvent.side === 'right') {
          if (!this.dateCompare(new Date(this.events[this.transferEvent.eventIndex].dateFrom), '>', new Date(this.calendar[obj.week][obj.day].fullDate))) {

            if (this.events[this.transferEvent.eventIndex].status === 'nextMonth') {
              for (let week in this.calendar) {
                for (let day in this.calendar[week]) {
                  for (let event in this.calendar[week][day].events) {
                    if (this.calendar[week][day].events[event].ID == copyEventPreviewClone.ID && this.calendar[week][day].events[event].continue) {
                      this.calendar[week][day].events.splice(event, 1);
                    }
                  }
                }
              }


              let dateFrom = this.events[this.transferEvent.eventIndex].dateFrom;
              let dateTo = this.calendar[obj.week][obj.day].fullDate;

              let firstDayInMonth = this.calendar[0][0].fullDate;

              let duration = this.getDuration(firstDayInMonth, dateTo);


              // ВРОДЕ КАК СДЕЛАНО
              let dayOfWeek = this.getDayOfWeekWithSettings(new Date(firstDayInMonth).getDay()); // Получаем день недели event в зависимости от первого дня недели
              let countDuration = dayOfWeek + duration; // Счётчик длительности для определения недели. Изначально == День недели события + Длительность
              let countWeek = 1; // Счётчик недель, которые будем прибавлять
              this.events[this.transferEvent.eventIndex].duration = duration;
              this.events[this.transferEvent.eventIndex].totalDuration = this.getDuration(dateFrom, dateTo);

              // let duration = this.getDuration(this.events[event].dateFrom, this.events[event].dateTo);
              // let countDuration = dayOfWeek + duration; // Счётчик длительности для определия недели. Изначально == День недели события + Длительность
              // let countWeek = 1; // Счётчик недель, которые будем прибавлять
              // this.events[event].totalDuration = duration;


              if (duration !== 1) { // Если длительность больше 1 дня, то для сортировки надо в следующие дни добавить копию события и сделать его невидимым
                let copyEventClone = Object.assign({}, this.events[this.transferEvent.eventIndex]); // Копируем основное событие для передачи дальше
                copyEventClone.duration = 1;
                copyEventClone.clone = true;
                let setEventClones = duration;
                let setEventClonesDayOfWeek = dayOfWeek + 1;
                let setEventClonesWeek = obj.week;
                while (setEventClones > 0) {
                  if (setEventClonesDayOfWeek === 8) {
                    setEventClonesDayOfWeek = 2;
                    setEventClonesWeek++;
                    setEventClones--;
                    continue;
                  }
                  this.setEventInDay(setEventClonesWeek, setEventClonesDayOfWeek - 1, copyEventClone)
                  setEventClones--;
                  setEventClonesDayOfWeek++;
                }
              }

              if (countDuration > 8) {
                this.events[this.transferEvent.eventIndex].lastWeek = false;
                let leftDays = this.getRestDaysOnNextWeek(duration, dayOfWeek);
                while (leftDays > 0) { // Если счётчик длительности больше 7, то надо перенести событие на следующую неделю

                  if (!this.calendar[countWeek]) break;

                  let copyEvent = Object.assign({}, this.events[this.transferEvent.eventIndex]); // Копируем основное событие для передачи дальше
                  copyEvent.continue = true;

                  if (leftDays > 7) {
                    copyEvent.duration = 7;
                  } else {
                    copyEvent.duration = leftDays;
                    copyEvent.lastWeek = true;
                  }
                  this.setEventInDay(countWeek, 0, copyEvent)
                  leftDays = Number(leftDays) - 7;
                  countWeek++;
                }
              } else {
                this.events[this.transferEvent.eventIndex].lastWeek = true;
              }

              this.events[this.transferEvent.eventIndex].continue = true;
              this.events[this.transferEvent.eventIndex].place = true;
              this.events[this.transferEvent.eventIndex].status = 'nextMonth';
              this.setEventInDay(0, 0, this.events[this.transferEvent.eventIndex])


            } else {

              let placeWeek;
              let placeDay;

              for (let week in this.calendar) {
                for (let day in this.calendar[week]) {
                  for (let event in this.calendar[week][day].events) {
                    if (this.calendar[week][day].events[event].ID == copyEventPreviewClone.ID) {
                      if (this.dateCompare(new Date(this.calendar[week][day].fullDate), '===', new Date(this.calendar[week][day].events[event].dateFrom))) {
                        placeWeek = week;
                        placeDay = day;
                      }
                    }
                    if (this.calendar[week][day].events[event].ID == copyEventPreviewClone.ID && this.calendar[week][day].events[event].continue) {
                      this.calendar[week][day].events.splice(event, 1);
                    }
                  }
                }
              }


              let timeTo = this.getTimeOfDate(copyEventPreviewClone.dateTo);
              let dateTo = new Date(this.calendar[this.weekDisabled][this.dayDisabled].fullDate);
              dateTo.setHours(timeTo.hour, timeTo.min, timeTo.sec, 0);
              copyEventPreviewClone.dateTo = dateTo;

              let duration = this.getDuration(copyEventPreviewClone.dateFrom, dateTo);

              // СДЕЛАНО
              this.events[this.transferEvent.eventIndex].duration = duration;
              this.events[this.transferEvent.eventIndex].preview = true;
              let dayOfWeek = this.getDayOfWeekWithSettings(new Date(copyEventPreviewClone.dateFrom).getDay()); // Получаем день недели event в зависимости от первого дня недели
              let countDuration = dayOfWeek + duration; // Счётчик длительности для определия недели. Изначально == День недели события + Длительность
              let countWeek = 1; // Счётчик недель, которые будем прибавлять


              if (countDuration > 8) { // Если событие не помещается в одну неделю, то
                copyEventPreviewClone.lastWeek = false;
                let leftDays = this.getRestDaysOnNextWeek(duration, dayOfWeek); // Осталось дней
                while (leftDays > 0) { // Если счётчик длительности больше 7, то надо перенести событие на следующую неделю
                  if (!this.calendar[(Number(placeWeek) + countWeek)]) break;

                  let copyEvent = Object.assign({}, copyEventPreviewClone); // Копируем основное событие для передачи дальше
                  copyEvent.continue = true;

                  if (leftDays > 7) {
                    copyEvent.duration = 7;
                  } else {
                    copyEvent.duration = leftDays;
                    copyEvent.lastWeek = true;
                  }
                  this.calendar[Number(placeWeek) + countWeek][0].events.unshift(copyEvent);
                  leftDays = Number(leftDays) - 7;
                  countWeek++;
                }
              } else {
                copyEventPreviewClone.duration = duration;
                copyEventPreviewClone.lastWeek = true;
              }
              if (duration === 1) {
                copyEventPreviewClone.lastWeek = true;
              }
            }
          }
        }

      } else if (this.transferEvent.event !== 'transferWaitingList') {

        if (this.waiting_events.length) {
          for (let i in this.waiting_events) {
            if (this.waiting_events[i].ID == this.events[this.transferEvent.eventIndex].ID && this.waiting_events[i].color == this.events[this.transferEvent.eventIndex].color) {
              this.waiting_events.splice(i, 1);
              break;
            }
          }
        } else {
          this.waiting_events = [];
        }


        for (let week in this.calendar) {
          for (let day in this.calendar[week]) {
            for (let event in this.calendar[week][day].events) {
              if (this.calendar[week][day].events[event].preview || this.calendar[week][day].events[event].ID == copyEventPreviewClone.ID) {
                this.calendar[week][day].events.splice(event, 1);
              }
            }
          }
        }

        // СДЕЛАНО
        let duration = this.getDuration(copyEventPreviewClone.dateFrom, copyEventPreviewClone.dateTo);
        let dayOfWeek = this.getDayOfWeekWithSettings(new Date(this.calendar[this.weekDisabled][this.dayDisabled].fullDate).getDay()); // Получаем день недели event в зависимости от первого дня недели
        let countDuration = dayOfWeek + duration; // Счётчик длительности для определия недели. Изначально == День недели события + Длительность
        let countWeek = 1; // Счётчик недель, которые будем прибавлять

        if (countDuration > 8) { // Если событие не помещается в одну неделю, то
          copyEventPreviewClone.lastWeek = false;
          let leftDays = this.getRestDaysOnNextWeek(duration, dayOfWeek); // Осталось дней
          while (leftDays > 0) { // Если счётчик длительности больше 7, то надо перенести событие на следующую неделю

            if (!this.calendar[(Number(this.weekDisabled) + countWeek)]) break;

            let copyEvent = Object.assign({}, copyEventPreviewClone); // Копируем основное событие для передачи дальше
            copyEvent.continue = true;

            if (leftDays > 7) {
              copyEvent.duration = 7;
            } else {
              copyEvent.duration = leftDays;
              copyEvent.lastWeek = true;
            }
            this.calendar[Number(this.weekDisabled) + countWeek][0].events.unshift(copyEvent);
            leftDays = Number(leftDays) - 7;
            countWeek++;
          }
        } else {
          copyEventPreviewClone.duration = duration;
          copyEventPreviewClone.lastWeek = true;
        }
        if (duration === 1) {
          copyEventPreviewClone.lastWeek = true;
        }

        copyEventPreviewClone.duration = duration;
        this.calendar[this.weekDisabled][this.dayDisabled].events.unshift(copyEventPreviewClone);

      }


    },

    onDragStartDay(e, eventID, resize = false, side = false, obj) {
      let img = new Image();
      img.src = '';
      e.dataTransfer.setDragImage(img, 10, 10);
      this.dragActivate = true;
      let eventIndex;

      if (!obj) {
        eventIndex = eventID;
      } else {
        for (let i in this.events) {
          if (this.events[i].ID === eventID) {
            eventIndex = i;
            break;
          }
        }
      }

      e.dataTransfer.setData("text/plain", 'forSupportMobile');

      if (resize) {
        e.dataTransfer.effectAllowed = "link";
        e.target.style.cursor = 'link';
        this.transferEvent = {eventIndex: eventIndex, event: 'resize', side: side, obj: obj};
      } else {
        e.dataTransfer.effectAllowed = "move";
        e.target.style.cursor = 'move';
        if (!obj) {
          this.transferEvent = {eventIndex: eventIndex, event: 'transferWaitingList', side: side, obj: null};
        } else {
          this.transferEvent = {eventIndex: eventIndex, event: 'transfer', side: side, obj: obj};
        }
      }


    },
    onDragStartWeek(e, eventID, resize = false, side = false, obj) {

      let img = new Image();
      img.src = '';
      e.dataTransfer.setDragImage(img, 10, 10);
      this.dragActivate = true;
      let eventIndex;

      if (!obj) {
        eventIndex = eventID;
      } else {
        for (let i in this.events) {
          if (this.events[i].ID === eventID) {
            eventIndex = i;
            break;
          }
        }
      }

      e.dataTransfer.setData("text/plain", 'forSupportMobile');

      if (resize) {
        e.dataTransfer.effectAllowed = "link";
        e.target.style.cursor = 'link';
        this.transferEvent = {eventIndex: eventIndex, event: 'resize', side: side, obj: obj};
      } else {
        e.dataTransfer.effectAllowed = "move";
        e.target.style.cursor = 'move';
        if (!obj) {
          this.transferEvent = {eventIndex: eventIndex, event: 'transferWaitingList', side: side, obj: null};
        } else {
          this.transferEvent = {eventIndex: eventIndex, event: 'transfer', side: side, obj: obj};
        }
      }


    },

    onDragStart(e, eventID, resize = false, side = false, obj) {


      let img = new Image();
      img.src = '';
      e.dataTransfer.setDragImage(img, 10, 10);
      this.dragActivate = true;
      let eventIndex;

      if (!obj) {
        eventIndex = eventID;
      } else {
        for (let i in this.events) {
          if (this.events[i].ID === eventID) {
            eventIndex = i;
            break;
          }
        }
      }

      e.dataTransfer.setData("text/plain", 'forSupportMobile');

      if (resize) {
        e.dataTransfer.effectAllowed = "link";
        e.target.style.cursor = 'link';
        this.transferEvent = {eventIndex: eventIndex, event: 'resize', side: side, obj: obj};
      } else {
        e.dataTransfer.effectAllowed = "move";
        e.target.style.cursor = 'move';
        if (!obj) {
          this.transferEvent = {eventIndex: eventIndex, event: 'transferWaitingList', side: side, obj: null};
        } else {
          this.transferEvent = {eventIndex: eventIndex, event: 'transfer', side: side, obj: obj};
        }
      }

    },
    async setEventDefaultOptions() {
      for (let i = this.events.length - 1; i >= 0; --i) {
        this.events[i].place = null;
        this.events[i].stringForWeek = 0;
        this.events[i].height = 1;
        this.events[i].count_in_slot = 0;
        this.events[i].transfer_waiting = false;
        this.events[i].string = 0;
      }
    },
    async onDropDay(e, obj) {

      if (!obj.valid) {
        await this.clearCalendarEvenst();
        await this.setEventDefaultOptions();
        await this.loadEventsInCalendar();
        this.dayDisabled = null;
        this.slotDisabled = null;
        this.weekDisabled = null;
        this.addAlert('Нельзя поместить событие в прошедший день');
        return false;
      }

      if (this.transferEvent.event === 'transfer') {

        await this.clearCalendarEvenst();

        let dateSlotToTransferFrom;
        let dateEventFrom = (new Date(this.events[Number(this.transferEvent.eventIndex)].dateFrom)).getTime();
        let dateEventTo = (new Date(this.events[Number(this.transferEvent.eventIndex)].dateTo)).getTime();
        let eventHeight = this.events[Number(this.transferEvent.eventIndex)].height;

        let indexSlotPlusHeight = Number(obj.slot) + Number(eventHeight) - 1;

        let dateSlotToTransferTo;

        if (this.day.slots[indexSlotPlusHeight]) {
          dateSlotToTransferTo = new Date(this.day.slots[indexSlotPlusHeight].fullDate);
          dateSlotToTransferFrom = new Date(this.day.slots[obj.slot].fullDate);
        } else {
          let indexLastSlot = this.day.slots.length - 1;
          let indexStartSlot = this.day.slots.length - Number(eventHeight);
          dateSlotToTransferFrom = new Date(this.day.slots[indexStartSlot].fullDate);
          dateSlotToTransferTo = new Date(this.day.slots[indexLastSlot].fullDate);
        }

        this.events[Number(this.transferEvent.eventIndex)].dateFrom = dateSlotToTransferFrom;
        this.events[Number(this.transferEvent.eventIndex)].dateTo = dateSlotToTransferTo;

        await this.saveNewDateEvent(this.events[Number(this.transferEvent.eventIndex)].ID, dateSlotToTransferFrom, dateSlotToTransferTo);
        await this.reloadCalendarAfterEditDate();
      } else if (this.transferEvent.event === 'resize') {

        let timeSlotToResize = (new Date(this.day.slots[obj.slot].fullDate)).getTime(); // Время слота (15:30)
        let timeEventFrom = (new Date(this.events[Number(this.transferEvent.eventIndex)].dateFrom)).getTime(); // Получаем следующий слот в миллисекундах
        let timeEventTo = (new Date(this.events[Number(this.transferEvent.eventIndex)].dateTo)).getTime(); // Получаем следующий слот в миллисекундах

        await this.clearCalendarEvenst();
        if (this.transferEvent.side === 'top') {
          if (timeSlotToResize <= timeEventTo) {
            this.events[Number(this.transferEvent.eventIndex)].dateFrom = new Date(timeSlotToResize);
            this.events[Number(this.transferEvent.eventIndex)].preview = false;
            await this.saveNewDateEvent(this.events[Number(this.transferEvent.eventIndex)].ID, new Date(timeSlotToResize), new Date(timeEventTo));
          } else {
          }
        } else if (this.transferEvent.side === 'bottom') {
          if (timeSlotToResize >= timeEventFrom) {
            this.events[Number(this.transferEvent.eventIndex)].dateTo = new Date(timeSlotToResize);
            this.events[Number(this.transferEvent.eventIndex)].preview = false;
            await this.saveNewDateEvent(this.events[Number(this.transferEvent.eventIndex)].ID, new Date(timeEventFrom), new Date(timeSlotToResize));
          } else {
          }

        }
        await this.reloadCalendarAfterEditDate();
      } else if (this.transferEvent.event === 'transferWaitingList') {
        for (let i in this.waiting_events) {
          if (this.waiting_events[i].ID == this.transferEvent.eventIndex) {
            this.waiting_events[i].transfer_waiting = true;
            this.setEditEvent(this.waiting_events[i]);
            this.setModalType('edit');
            this.showMore(null, null);
            this.editEvent.dateFrom = new Date(this.day.slots[obj.slot].fullDate);
            this.editEvent.dateTo = new Date(this.day.slots[obj.slot].fullDate);
          }
        }

      } else {
        console.log("Неизвестная команда")
      }

      this.dayDisabled = null;
      this.slotDisabled = null;
      this.weekDisabled = null;
    },
    async onDropWeek(e, obj) {

      if (!obj.valid) {
        await this.clearCalendarEvenst();
        await this.setEventDefaultOptions();
        await this.loadEventsInCalendar();
        this.dayDisabled = null;
        this.slotDisabled = null;
        this.weekDisabled = null;
        this.addAlert('Нельзя поместить событие в прошедший день');
        return false;
      }

      if (this.transferEvent.event === 'transfer') {

        await this.clearCalendarEvenst();

        let dateSlotToTransferFrom;
        let dateEventFrom = (new Date(this.events[Number(this.transferEvent.eventIndex)].dateFrom)).getTime();
        let dateEventTo = (new Date(this.events[Number(this.transferEvent.eventIndex)].dateTo)).getTime();
        let eventHeight = this.events[Number(this.transferEvent.eventIndex)].height;

        let indexSlotPlusHeight = Number(obj.slot) + Number(eventHeight) - 1;

        let dateSlotToTransferTo;

        if (this.week[obj.day].slots[indexSlotPlusHeight]) {
          dateSlotToTransferTo = new Date(this.week[obj.day].slots[indexSlotPlusHeight].fullDate);
          dateSlotToTransferFrom = new Date(this.week[obj.day].slots[obj.slot].fullDate);
        } else {
          let indexLastSlot = this.week[obj.day].slots.length - 1;
          let indexStartSlot = this.week[obj.day].slots.length - Number(eventHeight);
          dateSlotToTransferFrom = new Date(this.week[obj.day].slots[indexStartSlot].fullDate);
          dateSlotToTransferTo = new Date(this.week[obj.day].slots[indexLastSlot].fullDate);
        }

        this.events[Number(this.transferEvent.eventIndex)].dateFrom = dateSlotToTransferFrom;
        this.events[Number(this.transferEvent.eventIndex)].dateTo = dateSlotToTransferTo;

        await this.saveNewDateEvent(this.events[Number(this.transferEvent.eventIndex)].ID, dateSlotToTransferFrom, dateSlotToTransferTo);
        await this.reloadCalendarAfterEditDate();

      } else if (this.transferEvent.event === 'resize') {


        if (this.transferEvent.obj.day != obj.day) {
          return false;
        }
        let timeSlotToResize = (new Date(this.week[obj.day].slots[obj.slot].fullDate)).getTime(); // Время слота (15:30)
        let timeEventFrom = (new Date(this.events[Number(this.transferEvent.eventIndex)].dateFrom)).getTime(); // Получаем следующий слот в миллисекундах
        let timeEventTo = (new Date(this.events[Number(this.transferEvent.eventIndex)].dateTo)).getTime(); // Получаем следующий слот в миллисекундах

        await this.clearCalendarEvenst();


        if (this.transferEvent.side === 'top') {
          if (timeSlotToResize <= timeEventTo) {
            this.events[Number(this.transferEvent.eventIndex)].dateFrom = new Date(timeSlotToResize);
            this.events[Number(this.transferEvent.eventIndex)].preview = false;
            await this.saveNewDateEvent(this.events[Number(this.transferEvent.eventIndex)].ID, new Date(timeSlotToResize), new Date(timeEventTo));
          } else {
          }
        } else if (this.transferEvent.side === 'bottom') {
          if (timeSlotToResize >= timeEventFrom) {
            this.events[Number(this.transferEvent.eventIndex)].dateTo = new Date(timeSlotToResize);
            this.events[Number(this.transferEvent.eventIndex)].preview = false;
            await this.saveNewDateEvent(this.events[Number(this.transferEvent.eventIndex)].ID, new Date(timeEventFrom), new Date(timeSlotToResize));
          } else {
          }
        }
        await this.reloadCalendarAfterEditDate();
      } else if (this.transferEvent.event === 'transferWaitingList') {

        for (let i in this.waiting_events) {
          if (this.waiting_events[i].ID == this.transferEvent.eventIndex) {

            this.waiting_events[i].transfer_waiting = true;
            this.setEditEvent(this.waiting_events[i]);
            this.setModalType('edit');
            this.showMore(null, null);
            this.editEvent.dateFrom = new Date(this.week[obj.day].slots[obj.slot].fullDate);
            this.editEvent.dateTo = new Date(this.week[obj.day].slots[obj.slot].fullDate);
          }
        }

      } else {
        console.log("Неизвестная команда")
      }

      this.dayDisabled = null;
      this.slotDisabled = null;
      this.weekDisabled = null;
    },

    async onDrop(e, obj) {

      if (!obj.valid) {
        await this.clearCalendarEvenst();
        await this.setEventDefaultOptions();
        await this.loadEventsInCalendar();
        this.dayDisabled = null;
        this.slotDisabled = null;
        this.weekDisabled = null;
        this.addAlert('Нельзя поместить событие в прошедший день');
        return false;
      }

      if (this.transferEvent.event === 'transfer') {
        await this.clearCalendarEvenst();

        let timeFrom = this.getTimeOfDate(this.events[Number(this.transferEvent.eventIndex)].dateFrom);
        let timeTo = this.getTimeOfDate(this.events[Number(this.transferEvent.eventIndex)].dateTo);

        let dateFrom = new Date(this.calendar[obj.week][obj.day].fullDate);
        dateFrom.setHours(timeFrom.hour, timeFrom.min, timeFrom.sec, 0);
        let dateTo = new Date(this.calendar[obj.week][obj.day].fullDate);
        let duration = this.getDuration(this.events[Number(this.transferEvent.eventIndex)].dateFrom, this.events[Number(this.transferEvent.eventIndex)].dateTo);
        dateTo.setDate(dateTo.getDate() + duration - 1);
        dateTo.setHours(timeTo.hour, timeTo.min, timeTo.sec, 0);
        this.events[Number(this.transferEvent.eventIndex)].dateFrom = dateFrom;
        this.events[Number(this.transferEvent.eventIndex)].dateTo = dateTo;
        await this.saveNewDateEvent(this.events[Number(this.transferEvent.eventIndex)].ID, dateFrom, dateTo);
        await this.reloadCalendarAfterEditDate();
      } else if (this.transferEvent.event === 'resize') {
        await this.clearCalendarEvenst();

        if (this.transferEvent.side === 'left') {
          if (!this.dateCompare(new Date(this.calendar[obj.week][obj.day].fullDate), '>', new Date(this.events[Number(this.transferEvent.eventIndex)].dateTo))) {
            let timeFrom = this.getTimeOfDate(this.events[Number(this.transferEvent.eventIndex)].dateFrom);
            let dateFrom = new Date(this.calendar[obj.week][obj.day].fullDate);
            dateFrom.setHours(timeFrom.hour, timeFrom.min, timeFrom.sec, 0);
            this.events[Number(this.transferEvent.eventIndex)].dateFrom = dateFrom;
            this.events[Number(this.transferEvent.eventIndex)].preview = false;
            await this.saveNewDateEvent(this.events[Number(this.transferEvent.eventIndex)].ID, dateFrom, this.events[Number(this.transferEvent.eventIndex)].dateTo);
          }
        } else if (this.transferEvent.side === 'right') {
          if (!this.dateCompare(new Date(this.events[Number(this.transferEvent.eventIndex)].dateFrom), '>', new Date(this.calendar[obj.week][obj.day].fullDate))) {
            let timeTo = this.getTimeOfDate(this.events[this.transferEvent.eventIndex].dateTo);
            let dateTo = new Date(this.calendar[obj.week][obj.day].fullDate);
            dateTo.setHours(timeTo.hour, timeTo.min, timeTo.sec, 0);
            this.events[Number(this.transferEvent.eventIndex)].dateTo = dateTo;
            this.events[Number(this.transferEvent.eventIndex)].preview = false;
            await this.saveNewDateEvent(this.events[Number(this.transferEvent.eventIndex)].ID, this.events[Number(this.transferEvent.eventIndex)].dateFrom, dateTo);
          }
        }
        await this.reloadCalendarAfterEditDate();
      } else if (this.transferEvent.event === 'transferWaitingList') {
        for (let i in this.waiting_events) {
          if (this.waiting_events[i].ID == this.transferEvent.eventIndex) {

            this.waiting_events[i].transfer_waiting = true;
            this.setEditEvent(this.waiting_events[i]);
            this.setModalType('edit');
            this.showMore(null, null);
            this.editEvent.dateFrom = new Date(this.calendar[obj.week][obj.day].fullDate);
            this.editEvent.dateTo = new Date(this.calendar[obj.week][obj.day].fullDate);
          }
        }
      } else {
        console.log("Неизвестная команда")
      }

      this.dayDisabled = null;
      this.slotDisabled = null;
      this.weekDisabled = null;
    },

    checkDateEntrance(dateFrom, dateTo, dateDay) {
      return new Date(dateDay) >= new Date(dateFrom) && new Date(dateDay) <= new Date(dateTo);
    },
    getTimeOfDate(date) {
      date = new Date(date);
      return {hour: date.getHours(), min: date.getMinutes(), sec: date.getSeconds()}
    },
    getDuration(dateFrom, dateTo) {
      let one = new Date(new Date(dateFrom).getFullYear(), new Date(dateFrom).getMonth(), new Date(dateFrom).getDate());
      let two = new Date(new Date(dateTo).getFullYear(), new Date(dateTo).getMonth(), new Date(dateTo).getDate());
      let millisecondsPerDay = 1000 * 60 * 60 * 24;
      let millisBetween = two.getTime() - one.getTime();
      let days = millisBetween / millisecondsPerDay;
      return Math.floor(days) + 1;
    },
    async getCalendar(firstLoad) {
      this.loadMonth();
      this.loadWeek(firstLoad);
      this.loadDay();
      return true;
    },
    checkDateForValid(date) { // Если дата уже прошла, то делаем её неактивной
      if (!this.dateCompare(new Date(date), '>', new Date()) && !this.dateCompare(new Date(date), '===', new Date())) {
        return false;
      } else {
        return true;
      }
    },
    loadMonth() {
      let month = []; // Массив месяца, содержит недели
      let week = 0; // счётчик недели
      month[week] = []; // массив недели, содержит дни
      let lastDayInMonth = new Date(this.year, this.month + 1, 0).getDate(); // Последний день месяца
      for (let i = 1; i <= lastDayInMonth; i++) {  // Пока день меньше последнего дня месяца
        if (new Date(this.year, this.month, i).getDay() != this.settings.first_day) {
          let a = {
            num: i,
            fullDate: new Date(this.year, this.month, i),
            valid: this.checkDateForValid(new Date(this.year, this.month, i)),
            year: this.year,
            month: this.month,
            day: i,
            events: [],
            today: this.dateCompare(new Date(this.year, this.month, i), '===', this.today),
          };
          month[week].push(a);
        } else {
          week++;
          month[week] = [];
          let a = {
            num: i,
            fullDate: new Date(this.year, this.month, i),
            valid: this.checkDateForValid(new Date(this.year, this.month, i)),
            year: this.year,
            month: this.month,
            day: i,
            events: [],
            today: this.dateCompare(new Date(this.year, this.month, i), '===', this.today),
          };
          month[week].push(a);
        }
      }
      // Дополняем первую неделю днями из прошлого месяца
      if (month[0].length > 0) {
        let k = 0;
        for (let i = month[0].length; i < 7; i++) {
          let lastDayInPrevMonth = new Date(this.year, this.month, 0).getDate() - k; // Последний день месяца
          let a = {
            num: lastDayInPrevMonth,
            fullDate: new Date(this.year, this.month - 1, lastDayInPrevMonth),
            valid: this.checkDateForValid(new Date(this.year, this.month - 1, lastDayInPrevMonth)),
            year: this.year,
            month: this.month - 1,
            day: lastDayInPrevMonth,
            events: [],
            today: this.dateCompare(new Date(this.year, this.month - 1, lastDayInPrevMonth), '===', this.today),
          };
          month[0].unshift(a);
          k++;
        }
      }

      // Дополняем последнюю неделю следующим месяцем
      if (month[month.length - 1].length !== 7) {
        let k = 1;
        while (month[month.length - 1].length < 7) {
          let a = {
            num: k,
            fullDate: new Date(this.year, this.month + 1, k),
            valid: this.checkDateForValid(new Date(this.year, this.month + 1, k)),
            year: this.year,
            month: this.month + 1,
            day: k,
            events: [],
            today: this.dateCompare(new Date(this.year, this.month + 1, k), '===', this.today),
          };
          month[month.length - 1].push(a);
          k++;
        }
      }


      this.calendar = month;
      if (this.calendar[0].length == 0) { // Если в месяц начинается с 1 числа, то удаляем 1 массив в календаре
        this.calendar.splice(0, 1);
      }
    },

    getCountSlotsInDay(start, end) {

      let timeStart = start.split(':');
      let hourStart = timeStart[0];
      let minStart = timeStart[1];

      let startDate = new Date(this.today.setHours(hourStart, minStart, 0, 0));

      let timeEnd = end.split(':');
      let hourEnd = timeEnd[0];
      let minEnd = timeEnd[1];

      let endDate = new Date(this.today.setHours(hourEnd, minEnd, 0, 0));

      let res = ((new Date(endDate)).getTime() - (new Date(startDate)).getTime()) / 60000;

      res = res / this.settings.slot_duration;

      if (!Number.isInteger(res)) {
        this.deleteLastHalfSlot = true;
        return Math.ceil(res);
      } else {
        return res;
      }

    },

    loadWeek(firstLoad = true) { // Загружаем клетки для формата Week

      if (firstLoad) {
        for (let search in this.calendar) {
          for (let searchDate in this.calendar[search]) {
            if (this.dateCompare(new Date(this.calendar[search][searchDate].fullDate), '===', new Date())) {
              this.format_week.day = [this.calendar[search][0].fullDate, this.calendar[search][this.calendar[search].length - 1].fullDate];
              this.defaultValuesPeriodWeek = this.format_week.day;
            }
          }
        }
      }


      let timeStart = this.settings.start_time;
      let timeEnd = this.settings.end_time;

      let countSlotsInDay = this.getCountSlotsInDay(timeStart, timeEnd);
      let count = countSlotsInDay;

      let week = [];
      let day = 0;

      for (let k = 0; k < this.days.length; k++) {
        week[day] = {
          slots: [],
          date: this.getDateForFormatWeekHeader(this.format_week.day[0], k),
          day_name: this.days[k],
        };

        let fullDateFirst = new Date((new Date(this.format_week.day[0])).setDate(new Date(this.format_week.day[0]).getDate() + k));
        let fullDateSecond = new Date((new Date(this.format_week.day[0])).setDate(new Date(this.format_week.day[0]).getDate() + k));

        for (let i = 1; i <= countSlotsInDay; i++) {

          let firstTime = (this.getTimeCalendar(i, true)).split(':');
          let first = fullDateFirst.setHours(firstTime[0], firstTime[1], 0, 0);

          let secondTime = (this.getTimeCalendar(i, true, true)).split(':');
          let second = fullDateSecond.setHours(secondTime[0], secondTime[1], 0, 0);

          week[day].slots.push({
            time: this.getTimeCalendar(i, true),
            fullDate: new Date(first),
            valid: this.checkDateForValid(new Date(first)),
            events: []
          })

          week[day].slots.push({
            time: this.getTimeCalendar(i, true, true),
            fullDate: new Date(second),
            valid: this.checkDateForValid(new Date(second)),
            events: []
          })

        }
        count = countSlotsInDay;
        day++;
      }

      if (this.deleteLastHalfSlot) {
        for (let i in week) {
          let array = week[i].slots[week[i].slots.length - 1];
          delete week[i].slots[week[i].slots.length - 1][1]
        }
      }
      this.week = week;
    },
    loadDay() { // Загружаем клетки для формата Day
      let timeStart = this.settings.start_time;
      let timeEnd = this.settings.end_time;
      let countSlotsInDay = this.getCountSlotsInDay(timeStart, timeEnd);
      let today = this.format_day;
      let day = {
        slots: [],
        date: new Date(today),
        day_name: this.days[this.getDayOfWeekWithSettings(new Date(today).getDay())],
      };
      let fullDateFirst = new Date(today);
      let fullDateSecond = new Date(today);
      for (let i = 1; i <= countSlotsInDay; i++) {
        let firstTime = (this.getTimeCalendar(i, true)).split(':');
        let first = new Date(fullDateFirst.setHours(firstTime[0], firstTime[1], 0, 0));

        let secondTime = (this.getTimeCalendar(i, true, true)).split(':');
        let second = fullDateSecond.setHours(secondTime[0], secondTime[1], 0, 0);

        day.slots.push({
          time: this.getTimeCalendar(i, true),
          fullDate: new Date(first),
          valid: this.checkDateForValid(new Date(first)),
          events: []
        })

        day.slots.push({
          time: this.getTimeCalendar(i, true, true),
          fullDate: new Date(second),
          valid: this.checkDateForValid(new Date(second)),
          events: []
        })
      }


      if (this.deleteLastHalfSlot) {
        for (let i in day) {
          let array = day[i].slots[day[i].slots.length - 1];
          delete day[i].slots[day[i].slots.length - 1][1];
        }
      }

      this.day = day;
    },

    decrease() {
      this.month--;
      if (this.month < 0) {
        this.month = 12;
        this.month--;
        this.year--;
      }
    },
    increase() {
      this.month++;
      if (this.month > 11) {
        this.month = -1;
        this.month++;
        this.year++;
      }
    },

    dateCompare(date1, sign, date2) {
      let day1 = date1.getDate();
      let mon1 = date1.getMonth();
      let year1 = date1.getFullYear();
      let day2 = date2.getDate();
      let mon2 = date2.getMonth();
      let year2 = date2.getFullYear();
      if (sign === '===') {
        if (day1 === day2 && mon1 === mon2 && year1 === year2) return true;
        else return false;
      } else if (sign === '>') {
        if (year1 > year2) return true;
        else if (year1 === year2 && mon1 > mon2) return true;
        else if (year1 === year2 && mon1 === mon2 && day1 > day2) return true;
        else return false;
      }
    },
    async getEvents() {
      try {
        const response = await axios.post(
            'property.php?getEvents',
            {
              headers: {
                'Content-Type': 'application/json',
              },
            },
        )

        return response.data

      } catch (e) {
        console.log(e);
        return false;
      }
    },

    checkEmptyString(week, day, eventID, countString) {
      let flag = true;
      for (let checkString in this.calendar[week][day].events) {
        if (this.calendar[week][day].events[checkString].ID != eventID) {
          if (this.calendar[week][day].events[checkString].string == countString) {
            flag = false;
            break;
          }
        }
      }
      return flag;
    },

    checkEmptyStringForEventInWeek(day, slotIndex, eventID, countString) {
      let flag = true;
      for (let event in this.week[day].slots[slotIndex].events) {
        if (this.week[day].slots[slotIndex].events[event].ID != eventID) {
          if (this.week[day].slots[slotIndex].events[event].stringForWeek == countString) {
            flag = false;
            break;
          }
        }
      }
      return flag;
    },

    checkEmptyStringForEventInDay(slotIndex, eventID, countString) {
      let flag = true;
      for (let event in this.day.slots[slotIndex].events) {
        if (this.day.slots[slotIndex].events[event].ID != eventID) {
          if (this.day.slots[slotIndex].events[event].stringForWeek == countString) {
            flag = false;
            break;
          }
        }
      }
      return flag;
    },


    loadEventsInDayFormat(indexEvent) { // Загружаем событие в день по формату Day
      let timeEventStart = (new Date(this.events[indexEvent].dateFrom)).getTime();
      let timeEventEnd = (new Date(this.events[indexEvent].dateTo)).getTime();

      for (let slot in this.day.slots) {
        let timeSlot = (new Date(this.day.slots[slot].fullDate)).getTime(); // Время слота (15:30)
        let mil_seconds = this.settings.slot_duration / 2; // Добавим половину слота из настроек для получения времени следующего слота
        let timeSlotNext = (new Date(this.day.slots[slot].fullDate)).getTime() + mil_seconds * 60000; // Получаем следующий слот в миллисекундах

        //&& !this.dateCompare(new Date(this.events[indexEvent].dateFrom), '===', new Date(timeSlotNext))

        let test = new Date(this.day.slots[slot].fullDate)


        if ((timeSlot < timeEventStart || timeSlot == timeEventStart) && (timeEventStart < timeSlotNext)) { // Если 18:30 больше чем время 1 слота или равно ему и время слота меньше чем время 2 слота
          let copyEvent = Object.assign({}, this.events[indexEvent]); // Копируем основное событие для передачи дальше
          let copyEventClone = Object.assign({}, this.events[indexEvent]); // Копируем основное событие для передачи дальше в качестве клона (его не будем показывать в календаре, но он нужен для правильного расставления событий в слоте
          copyEventClone.clone = true; // Параметр для клона

          let searchEventEndSlotIndex = Number(slot) + 1; // От текущего слота в дне для поиска

          while (searchEventEndSlotIndex != Number(this.day.slots.length)) { // Пока индекс поиска не будет равен длинне масива слотов + 1, чтобы добавить в последний тоже
            // Получаем время слота для сравнения  с концом Event

            let timeSlotForSearchEventEnd = (new Date(this.day.slots[searchEventEndSlotIndex].fullDate)).getTime();
            let mil_seconds = this.settings.slot_duration / 2;
            let timeSlotForSearchEventEndNext = (new Date(this.day.slots[searchEventEndSlotIndex].fullDate)).getTime() + mil_seconds * 60000;
            this.day.slots[searchEventEndSlotIndex].events.push(copyEventClone);
            if ((timeSlotForSearchEventEnd < timeEventEnd || timeSlotForSearchEventEnd == timeEventEnd) && (timeEventEnd < timeSlotForSearchEventEndNext)) {
              copyEvent.height = searchEventEndSlotIndex - Number(slot) + 1;
              copyEventClone.height = searchEventEndSlotIndex - Number(slot) + 1;
              this.events[indexEvent].height = searchEventEndSlotIndex - Number(slot) + 1;
              break;
            }
            searchEventEndSlotIndex++;
          }


          // Добавляем Event в текущий день. Он будем основным и он будет показываться во всю высоту
          this.day.slots[slot].events.push(copyEvent);


        }


      }

    },


    async loadRepeatEvents(event) {

      let eventDuration = this.getDuration(event.dateFrom, event.dateTo);
      if (eventDuration > event.repeat_every_days) {
        //console.log("У события длительность от и до больше, чем дней, через которое оно будет повторятся. Повторения не выведутся")
      } else {
        let copyEventRepeat = Object.assign({}, event); // Копируем основное событие для передачи дальше
        copyEventRepeat.repeat = true;

        let count_repeat = Number(event.count_repeat) // Количество повторений в календаре
        let duration = this.getDuration(copyEventRepeat.dateFrom, copyEventRepeat.dateTo); // Длительность события
        let repeat_every;
        if (event.event_repeat == 1) {
          repeat_every = event.repeat_every_days; // Повторение каждые n дней
        } else if (event.event_repeat == 2) {
          repeat_every = 7 * event.repeat_every_days; // Повторение каждые n дней
        } else if (event.event_repeat == 3) {
          repeat_every = 31 * event.repeat_every_days; // Повторение каждые n дней
        }


        let dateFrom;
        let dateTo;
        if (event.repeat_end != 0) {
          let dateEndRepeat = new Date(event.repeat_end);

          count_repeat = 5;
          while (true) {
            let copy = Object.assign({}, copyEventRepeat);

            dateFrom = new Date(copyEventRepeat.dateFrom);
            dateTo = new Date(copyEventRepeat.dateTo);

            let repeatEventDateFrom = dateFrom.setDate(Number(dateFrom.getDate()) + Number(repeat_every));
            let newDateTo = new Date(repeatEventDateFrom);
            let repeatEventDateTo = newDateTo.setDate(Number(newDateTo.getDate()) + Number(duration) - 1);

            if (this.dateCompare(new Date(repeatEventDateTo), '>', new Date(dateEndRepeat))) {
              break;
            } else if (this.dateCompare(new Date(repeatEventDateTo), '===', new Date(dateEndRepeat))) {
              break;
            }


            copyEventRepeat.dateFrom = new Date(repeatEventDateFrom);
            copy.dateFrom = new Date(repeatEventDateFrom);
            copyEventRepeat.dateTo = new Date(repeatEventDateTo);
            copy.dateTo = new Date(repeatEventDateTo);

            this.events.push(copy);
          }

        } else {
          while (count_repeat !== 0) {
            let copy = Object.assign({}, copyEventRepeat);

            dateFrom = new Date(copyEventRepeat.dateFrom);
            dateTo = new Date(copyEventRepeat.dateTo);

            let repeatEventDateFrom = dateFrom.setDate(Number(dateFrom.getDate()) + Number(repeat_every));

            let newDateTo = new Date(repeatEventDateFrom);
            let repeatEventDateTo = newDateTo.setDate(Number(newDateTo.getDate()) + Number(duration) - 1);

            copyEventRepeat.dateFrom = new Date(repeatEventDateFrom);
            copy.dateFrom = new Date(repeatEventDateFrom);
            copyEventRepeat.dateTo = new Date(repeatEventDateTo);
            copy.dateTo = new Date(repeatEventDateTo);

            this.events.push(copy);
            count_repeat--;
          }

        }
      }

    },

    async checkRepeats() {
      for (let load_repeat in this.events) {
        if (this.events[load_repeat].event_repeat && this.events[load_repeat].repeat_every_days > 0 && !this.events[load_repeat].repeat) {
          await this.loadRepeatEvents(this.events[load_repeat]);
        }
      }
    },

    async loadEventsInCalendar() {


      await this.checkRepeats();


      for (let event in this.events) {
        // Если у события начало и конец дня равен установленному в формате Day дню, то грузим туда
        if (
            this
                .dateCompare(new Date(this.day.date), '===', new Date(this.events[event].dateFrom)) && this.dateCompare(new Date(this.day.date), '===', new Date(this.events[event].dateTo))
        ) {
          // Проверяем попадает ли событие в день при формате календаря Day
          await this.loadEventsInDayFormat(event);
        }

        if (this.checkDateEntrance(new Date(this.format_week.day[0]), new Date(this.format_week.day[1]), new Date(this.events[event].dateFrom)) || this.dateCompare(new Date(this.events[event].dateFrom), '===', new Date(this.format_week.day[1]))) {
          for (let search in this.week) {
            if (this.dateCompare(new Date(this.week[search].date), '===', new Date(this.events[event].dateFrom))) {

              let timeEventStart = (new Date(this.events[event].dateFrom)).getTime();
              let timeEventEnd = (new Date(this.events[event].dateTo)).getTime();

              for (let slot in this.week[search].slots) {

                let timeSlot = (new Date(this.week[search].slots[slot].fullDate)).getTime(); // Время слота (15:30)
                let mil_seconds = this.settings.slot_duration / 2; // Добавим половину слота из настроек для получения времени следующего слота
                let timeSlotNext = (new Date(this.week[search].slots[slot].fullDate)).getTime() + mil_seconds * 60000; // Получаем следующий слот в миллисекундах

                if ((timeSlot < timeEventStart || timeSlot == timeEventStart) && (timeEventStart < timeSlotNext)) { // Если 18:30 больше чем время 1 слота или равно ему и время слота меньше чем время 2 слота

                  let copyEvent = Object.assign({}, this.events[event]); // Копируем основное событие для передачи дальше
                  let copyEventClone = Object.assign({}, this.events[event]); // Копируем основное событие для передачи дальше в качестве клона (его не будем показывать в календаре, но он нужен для правильного расставления событий в слоте
                  copyEventClone.clone = true; // Параметр для клона


                  if (this.dateCompare(new Date(this.week[search].date), '===', new Date(this.events[event].dateTo))) { // Если день равен текущему дню, значит надо посчитать сколько слотов займёт
                    let searchEventEndSlotIndex = Number(slot) + 1; // От текущего слота в дне для поиска

                    while (searchEventEndSlotIndex != (Number(this.week[search].slots.length))) { // Пока индекс поиска не будет равен длинне масива слотов, чтобы добавить в последний тоже
                      // Получаем время слота для сравнения  с концом Event
                      if (timeEventEnd < timeSlotNext) {
                        break;
                      }

                      let timeSlotForSearchEventEnd = (new Date(this.week[search].slots[searchEventEndSlotIndex].fullDate)).getTime();
                      let mil_seconds = this.settings.slot_duration / 2;
                      let timeSlotForSearchEventEndNext = (new Date(this.week[search].slots[searchEventEndSlotIndex].fullDate)).getTime() + mil_seconds * 60000;
                      this.week[search].slots[searchEventEndSlotIndex].events.push(copyEventClone);

                      if ((timeSlotForSearchEventEnd < timeEventEnd || timeSlotForSearchEventEnd == timeEventEnd) && (timeEventEnd < timeSlotForSearchEventEndNext)) {
                        copyEvent.height = searchEventEndSlotIndex - Number(slot) + 1;
                        copyEventClone.height = searchEventEndSlotIndex - Number(slot) + 1;
                        this.events[event].height = searchEventEndSlotIndex - Number(slot) + 1;
                        break;
                      }

                      searchEventEndSlotIndex++;
                    }


                    // Добавляем Event в текущий день. Он будем основным и он будет показываться во всю высоту
                    this.week[search].slots[slot].events.push(copyEvent);
                  } else {
                    // Если Event заканчивается не в этот день, значит надо добавить его в шапку
                  }

                }


              }

            }

          }


        }
      }


      for (let week in this.calendar) {
        for (let day in this.calendar[week]) {
          for (let event in this.events) {
            this.events[event].preview = false;

            if (this.dateCompare(new Date(this.events[event].dateFrom), '===', new Date(this.calendar[week][day].fullDate))) {

              let countString = 0;
              let resultString = this.checkEmptyString(week, day, this.events[event].ID, countString);
              while (!resultString) {
                countString++;
                resultString = this.checkEmptyString(week, day, this.events[event].ID, countString);
              }

              this.events[event].string = countString;

              // ОСНОВА
              let duration = this.getDuration(this.events[event].dateFrom, this.events[event].dateTo);
              let dayOfWeek = this.getDayOfWeekWithSettings(new Date(this.events[event].dateFrom).getDay()); // Получаем день недели event в зависимости от первого дня недели
              let countDuration = dayOfWeek + duration; // Счётчик длительности для определия недели. Изначально == День недели события + Длительность
              let countWeek = 1; // Счётчик недель, которые будем прибавлять
              this.events[event].duration = this.getDurationWithSettings(new Date(this.events[event].dateFrom).getDay());
              this.events[event].totalDuration = duration;

              if (duration !== 1) { // Если длительность больше 1 дня, то для сортировки надо в следующие дни добавить копию события и сделать его невидимым
                let copyEventClone = Object.assign({}, this.events[event]); // Копируем основное событие для передачи дальше
                copyEventClone.duration = 1;
                copyEventClone.clone = true;
                let setEventClones = duration - 1;
                let setEventClonesDayOfWeek = dayOfWeek + 1;
                let setEventClonesWeek = week;
                while (setEventClones > 0) {
                  if (setEventClonesDayOfWeek === 8) {
                    setEventClonesDayOfWeek = 2;
                    setEventClonesWeek++;
                    setEventClones--;
                    continue;
                  }
                  this.setEventInDay(setEventClonesWeek, setEventClonesDayOfWeek - 1, copyEventClone)
                  setEventClones--;
                  setEventClonesDayOfWeek++;
                }
              }


              if (countDuration > 8) {
                this.events[event].lastWeek = false;
                let leftDays = this.getRestDaysOnNextWeek(duration, dayOfWeek);
                while (leftDays > 0) { // Если счётчик длительности больше 7, то надо перенести событие на следующую неделю

                  if (!this.calendar[(Number(week) + countWeek)]) break;

                  let copyEvent = Object.assign({}, this.events[event]); // Копируем основное событие для передачи дальше
                  copyEvent.continue = true;

                  if (leftDays > 7) {
                    copyEvent.duration = 7;
                  } else {
                    copyEvent.duration = leftDays;
                    copyEvent.lastWeek = true;
                  }
                  this.setEventInDay(Number(week) + countWeek, 0, copyEvent)
                  leftDays = Number(leftDays) - 7;
                  countWeek++;
                }
              } else {
                this.events[event].duration = duration;
                this.events[event].lastWeek = true;
              }

              if (duration === 1) {
                this.events[event].lastWeek = true;
              }

              this.events[event].place = true;
              this.setEventInDay(week, day, this.events[event])
            } else if (this.checkDateEntrance(new Date(this.events[event].dateFrom), new Date(this.events[event].dateTo), new Date(this.calendar[week][day].fullDate))) {

              if (!this.dateCompare(new Date(this.events[event].dateFrom), '===', new Date(this.calendar[week][day].fullDate)) && !this.events[event].place) {

                let dateFrom = this.events[event].dateFrom;
                let dateTo = this.events[event].dateTo;

                let firstDayInMonth = this.calendar[0][0].fullDate;

                let duration = this.getDuration(firstDayInMonth, dateTo);

                // СДЕЛАНО
                let dayOfWeek = this.getDayOfWeekWithSettings(new Date(firstDayInMonth).getDay()); // Получаем день недели event в зависимости от первого дня недели
                let countDuration = dayOfWeek + duration; // Счётчик длительности для определения недели. Изначально == День недели события + Длительность
                let countWeek = 1; // Счётчик недель, которые будем прибавлять
                this.events[event].duration = duration;
                this.events[event].totalDuration = this.getDuration(dateFrom, dateTo);


                if (duration !== 1) { // Если длительность больше 1 дня, то для сортировки надо в следующие дни добавить копию события и сделать его невидимым
                  let copyEventClone = Object.assign({}, this.events[event]); // Копируем основное событие для передачи дальше
                  copyEventClone.duration = 1;
                  copyEventClone.clone = true;
                  let setEventClones = duration - 1;
                  let setEventClonesDayOfWeek = dayOfWeek + 1;
                  let setEventClonesWeek = week;
                  while (setEventClones > 0) {
                    if (setEventClonesDayOfWeek === 8) {
                      setEventClonesDayOfWeek = 2;
                      setEventClonesWeek++;
                      setEventClones--;
                      continue;
                    }
                    this.setEventInDay(setEventClonesWeek, setEventClonesDayOfWeek - 1, copyEventClone)
                    setEventClones--;
                    setEventClonesDayOfWeek++;
                  }
                }

                if (countDuration > 8) {
                  this.events[event].lastWeek = false;
                  let leftDays = this.getRestDaysOnNextWeek(duration, dayOfWeek);
                  while (leftDays > 0) { // Если счётчик длительности больше 7, то надо перенести событие на следующую неделю

                    if (!this.calendar[(Number(week) + countWeek)]) break;

                    let copyEvent = Object.assign({}, this.events[event]); // Копируем основное событие для передачи дальше
                    copyEvent.continue = true;

                    if (leftDays > 7) {
                      copyEvent.duration = 7;
                    } else {
                      copyEvent.duration = leftDays;
                      copyEvent.lastWeek = true;
                    }
                    this.setEventInDay(Number(week) + countWeek, 0, copyEvent)
                    leftDays = Number(leftDays) - 7;
                    countWeek++;
                  }
                } else {
                  this.events[event].lastWeek = true;
                }

                this.events[event].continue = true;
                this.events[event].place = true;
                this.events[event].status = 'nextMonth';
                this.setEventInDay(0, 0, this.events[event])

              }


            }

          }

        }
      }


      await this.afterLoadEventsNeedRecountStringNumber();
      await this.afterLoadEventsNeedRecountWidth();
      await this.afterLoadEventsNeedRecountMarginLeft();

      await this.afterLoadEventsNeedRecountStringNumberFormatDay();
      await this.afterLoadEventsNeedRecountWidthFormatDay();
      await this.afterLoadEventsNeedRecountMarginLeftFormatDay();

    }
    ,
    afterLoadEventsNeedRecountMarginLeftFormatDay() {

      for (let k in this.day.slots) { // Перебираем все слоты
        if (!this.day.slots[k].events.length) continue;
        //console.log("______Перебираем события в слоте_______")
        for (let e in this.day.slots[k].events) { // Перебираем все события в слоте
          let eventID = this.day.slots[k].events[e].ID; // ID События

          // Отступ слева будет равен колонке из функции afterLoadEventsNeedRecountStringNumber (stringForWeek)
          // умноженный на ширину из функции afterLoadEventsNeedRecountWidth (width)
          let margin_left = Number(this.day.slots[k].events[e].stringForWeek) * Number(this.day.slots[k].events[e].width);
          //console.log("У события " + this.week[i].slots[k].events[e].text + " отступ слева: " + margin_left + "%");
          this.day.slots[k].events[e].col = margin_left;
        }
      }

    },
    afterLoadEventsNeedRecountMarginLeft() { // Добавляем отступ слева у событий
      for (let i in this.week) {
        for (let k in this.week[i].slots) { // Перебираем все слоты
          if (!this.week[i].slots[k].events.length) continue;
          //console.log("______Перебираем события в слоте_______")
          for (let e in this.week[i].slots[k].events) { // Перебираем все события в слоте
            let eventID = this.week[i].slots[k].events[e].ID; // ID События

            // Отступ слева будет равен колонке из функции afterLoadEventsNeedRecountStringNumber (stringForWeek)
            // умноженный на ширину из функции afterLoadEventsNeedRecountWidth (width)
            let margin_left = Number(this.week[i].slots[k].events[e].stringForWeek) * Number(this.week[i].slots[k].events[e].width);
            //console.log("У события " + this.week[i].slots[k].events[e].text + " отступ слева: " + margin_left + "%");
            this.week[i].slots[k].events[e].col = margin_left;
          }
        }
      }
    },
    afterLoadEventsNeedRecountWidthFormatDay() {
      for (let k in this.day.slots) {
        if (!this.day.slots[k].events.length) continue;
        let width_for_events_in_slot = 100 / Number(this.day.slots[k].events.length);
        //console.log("Ширина события для этого слота: " + width_for_events_in_slot + ", событий в слоте: " + this.day.slots[k].events.length)

        for (let e in this.day.slots[k].events) {
          //console.log("______Перебираем события в слоте________")
          let eventID = this.day.slots[k].events[e].ID; // ID События
          if (this.day.slots[k].events[e].clone) {
            // Если событие - клон, то надо проверить ширину событий в слоте и ширину клона
            if (width_for_events_in_slot < this.day.slots[k].events[e].width) {
              // Если ширина события больше чем ширина событий в слоте, надо поменять ширину у данного события и его клонов
              for (let searchSlotForEditWidth in this.day.slots) { // Ищем клонов события и само событие
                for (let searchEventForEditWidth in this.day.slots[searchSlotForEditWidth].events) {
                  if (this.day.slots[searchSlotForEditWidth].events[searchEventForEditWidth].ID == eventID) { // Если ID равен тому, который мы ищем, то меняем ширину
                    this.day.slots[searchSlotForEditWidth].events[searchEventForEditWidth].width = width_for_events_in_slot;
                  }
                }
              }
            }
          } else {
            // Если событие не копия, то ставим ширину
            this.day.slots[k].events[e].width = width_for_events_in_slot;
            // Дальше ищем клонов этого события и стами им ширину
            for (let searchCopySlot in this.day.slots) {
              for (let searchCopyEvent in this.day.slots[searchCopySlot].events) {
                if (this.day.slots[searchCopySlot].events[searchCopyEvent].ID == eventID && this.day.slots[searchCopySlot].events[searchCopyEvent].clone) {
                  this.day.slots[searchCopySlot].events[searchCopyEvent].width = width_for_events_in_slot;
                  break;
                }
              }
            }
          }
        }
      }

    },
    afterLoadEventsNeedRecountWidth() { // Считаем ширину событий
      for (let i in this.week) {
        for (let k in this.week[i].slots) {
          if (!this.week[i].slots[k].events.length) continue;
          let width_for_events_in_slot = 100 / Number(this.week[i].slots[k].events.length);
          //console.log("Ширина события для этого слота: " + width_for_events_in_slot + ", событий в слоте: " + this.week[i].slots[k].events.length)

          for (let e in this.week[i].slots[k].events) {
            //console.log("______Перебираем события в слоте________")
            let eventID = this.week[i].slots[k].events[e].ID; // ID События
            if (this.week[i].slots[k].events[e].clone) {
              // Если событие - клон, то надо проверить ширину событий в слоте и ширину клона
              if (width_for_events_in_slot < this.week[i].slots[k].events[e].width) {
                // Если ширина события больше чем ширина событий в слоте, надо поменять ширину у данного события и его клонов
                for (let searchSlotForEditWidth in this.week[i].slots) { // Ищем клонов события и само событие
                  for (let searchEventForEditWidth in this.week[i].slots[searchSlotForEditWidth].events) {
                    if (this.week[i].slots[searchSlotForEditWidth].events[searchEventForEditWidth].ID == eventID) { // Если ID равен тому, который мы ищем, то меняем ширину
                      this.week[i].slots[searchSlotForEditWidth].events[searchEventForEditWidth].width = width_for_events_in_slot;
                    }
                  }
                }
              }
            } else {
              // Если событие не копия, то ставим ширину
              this.week[i].slots[k].events[e].width = width_for_events_in_slot;
              // Дальше ищем клонов этого события и стами им ширину
              for (let searchCopySlot in this.week[i].slots) {
                for (let searchCopyEvent in this.week[i].slots[searchCopySlot].events) {
                  if (this.week[i].slots[searchCopySlot].events[searchCopyEvent].ID == eventID && this.week[i].slots[searchCopySlot].events[searchCopyEvent].clone) {
                    this.week[i].slots[searchCopySlot].events[searchCopyEvent].width = width_for_events_in_slot;
                    break;
                  }
                }
              }
            }
          }
        }
      }
    },
    afterLoadEventsNeedRecountStringNumberFormatDay() {
      for (let k in this.day.slots) {
        for (let e in this.day.slots[k].events) {

          if (this.day.slots[k].events[e].clone) continue;
          let eventID = this.day.slots[k].events[e].ID;

          let countString = -1;
          let resultString = false;
          while (!resultString) {
            countString++;
            resultString = this.checkEmptyStringForEventInDay(k, eventID, countString);
          }

          this.day.slots[k].events[e].stringForWeek = countString;
          for (let searchCopySlot in this.day.slots) {
            for (let searchCopyEvent in this.day.slots[searchCopySlot].events) {
              if (this.day.slots[searchCopySlot].events[searchCopyEvent].ID == eventID && this.day.slots[searchCopySlot].events[searchCopyEvent].clone) {
                this.day.slots[searchCopySlot].events[searchCopyEvent].stringForWeek = countString;
              }
            }
          }


        }
      }
    },
    afterLoadEventsNeedRecountStringNumber() {
      for (let i in this.week) {
        for (let k in this.week[i].slots) {
          for (let e in this.week[i].slots[k].events) {

            if (this.week[i].slots[k].events[e].clone) continue;
            let eventID = this.week[i].slots[k].events[e].ID;

            let countString = -1;
            let resultString = false;
            while (!resultString) {
              countString++;
              resultString = this.checkEmptyStringForEventInWeek(i, k, eventID, countString);
            }

            this.week[i].slots[k].events[e].stringForWeek = countString;
            for (let searchCopySlot in this.week[i].slots) {
              for (let searchCopyEvent in this.week[i].slots[searchCopySlot].events) {
                if (this.week[i].slots[searchCopySlot].events[searchCopyEvent].ID == eventID && this.week[i].slots[searchCopySlot].events[searchCopyEvent].clone) {
                  this.week[i].slots[searchCopySlot].events[searchCopyEvent].stringForWeek = countString;
                }
              }
            }


          }
        }
      }
    },
    getRestDaysOnNextWeek(duration, day) {
      if (day == 7) {
        return duration - 1;
      } else if (day == 6) {
        return duration - 2;
      } else if (day == 5) {
        return duration - 3;
      } else if (day == 4) {
        return duration - 4;
      } else if (day == 3) {
        return duration - 5;
      } else if (day == 2) {
        return duration - 6;
      } else if (day == 1) {
        return duration - 7;
      }
    }
    ,

    setEventInDay(week, day, event) {
      if (!this.calendar[week]) return false;
      this.calendar[week][day].events.push(event);
    }
    ,

    async changePeriod() {
      await this.loadSettings(false);
      this.week = null;
      this.calendar = null;
      await this.clearCalendarEvenst();
      await this.getCalendar(false)
          .then(res => {
            this.getEvents()
                .then(res => {
                  for (let event in res) {
                    res[event].dateFrom = (new Date(res[event].dateFrom * 1000)).toString();
                    res[event].dateTo = (new Date(res[event].dateTo * 1000)).toString();

                    if (res[event].repeat_end != 0) {
                      res[event].repeat_end = (new Date(res[event].repeat_end * 1000)).toString();
                    }

                  }
                  return res;
                })
                .then(res => {
                  this.events = res;
                })
                .then(res => {
                  this.setEventDefaultOptions();
                  this.loadEventsInCalendar();
                })
          })
    }
    ,

    setCalendarDays(num) {
      if (num == 1) {
        this.setDays(["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]);
      } else if (num == 2) {
        this.setDays(["Tue", "Wed", "Thu", "Fri", "Sat", "Sun", "Mon"]);
      } else if (num == 3) {
        this.setDays(["Wed", "Thu", "Fri", "Sat", "Sun", "Mon", "Tue"]);
      } else if (num == 4) {
        this.setDays(["Thu", "Fri", "Sat", "Sun", "Mon", "Tue", "Wed"]);
      } else if (num == 5) {
        this.setDays(["Fri", "Sat", "Sun", "Mon", "Tue", "Wed", "Thu"]);
      } else if (num == 6) {
        this.setDays(["Sat", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri"]);
      } else if (num == 0) {
        this.setDays(["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]);
      }
    }
    ,

    loadSettings(firstLoad = true) {
      if (!localStorage.time_format) localStorage.time_format = 24; // Формат времени
      this.settings.time_format = localStorage.time_format ? localStorage.time_format : 24;

      if (!localStorage.calendar_format) localStorage.calendar_format = 'month';

      if (firstLoad) {
        this.settings.calendar_format = 'month';
      }

      if (!localStorage.start_time) localStorage.start_time = '08:00';
      this.settings.start_time = localStorage.start_time ? localStorage.start_time : '08:00';

      if (!localStorage.end_time) localStorage.end_time = '19:00';
      this.settings.end_time = localStorage.end_time ? localStorage.end_time : '19:00';

      if (!localStorage.first_day) localStorage.first_day = '2';
      this.settings.first_day = localStorage.first_day ? localStorage.first_day : '2';

      if (!localStorage.slot_duration) localStorage.slot_duration = '30';
      this.settings.slot_duration = localStorage.slot_duration ? localStorage.slot_duration : '30';

      this.setCalendarDays(this.settings.first_day);

      this.userTimeZone = this.getUserTimeZone();
    }
    ,

    async loadApplication(settings = true) {

      if (settings) {
        await this.loadSettings();
      }


    }
    ,

    getDateInterval(from, to, preview) {
      let timeFrom;
      let timeTo;

      let dateFrom;
      let dateTo;
      dateFrom = new Date(from); // Принимаем время в Unix и преобразуем в дату
      dateTo = new Date(to);

      let month = Number(dateFrom.getMonth());
      month = this.monthes[month].substring(0, 3);

      if (this.settings.time_format == 12) {
        let hoursFrom = dateFrom.getHours();
        let minutesFrom = dateFrom.getMinutes();
        let ampmFrom = hoursFrom >= 12 ? 'PM' : 'AM';
        hoursFrom = hoursFrom % 12;
        hoursFrom = hoursFrom ? hoursFrom : 12; // 0 = 12
        minutesFrom = minutesFrom < 10 ? '0' + minutesFrom : minutesFrom;
        timeFrom = hoursFrom + ':' + minutesFrom + ' ' + ampmFrom;

        let hoursTo = dateFrom.getHours();
        let minutesTo = dateFrom.getMinutes();
        let ampmTo = hoursTo >= 12 ? 'PM' : 'AM';
        hoursTo = hoursTo % 12;
        hoursTo = hoursTo ? hoursTo : 12; // 0 = 12
        minutesTo = minutesTo < 10 ? '0' + minutesTo : minutesTo;
        timeTo = hoursTo + ':' + minutesTo + ' ' + ampmTo;
      } else {
        timeFrom = this.getTimeEvent(dateFrom);
        timeTo = this.getTimeEvent(dateTo);
      }

      return month + ', ' + timeFrom + ' - ' + timeTo;

    }
    ,

    async getWaitingList() {
      try {
        const response = await axios.post(
            'property.php?getWaitingList',
            {
              headers: {
                'Content-Type': 'application/json',
              },
            },
        )

        return response.data

      } catch (e) {
        console.log(e);
        return false;
      }
    }
    ,

    getMaxWithInWeekCalendar(eventID, width) {
      for (let event in this.events) {
        if (this.events[event].ID == eventID) {
          break;
        }
      }
    }
    ,

  }
  ,
  computed: {
    ...
        mapState({
          settings: state => state.settings,
          modalType: state => state.modalType,
          days: state => state.days,
          editEvent: state => state.editEvent,
          updateData: state => state.updateData,
          acceptChange: state => state.acceptChange,
          itsWaitingEvent: state => state.itsWaitingEvent,
          globalAlerts: state => state.globalAlerts,
        }),
  }
  ,
  mounted() {
    this.loadApplication();
    this.getCalendar()
        .then(res => {
          window.addEventListener('keydown', this.listenEsc);
          this.updateWidth();
          this.getEvents()
              .then(res => {
                this.getWaitingList()
                    .then(res => {
                      if (res) {
                        for (let event_waiting in res) {
                          res[event_waiting].dateFrom = (new Date(res[event_waiting].dateFrom * 1000)).toString();
                          res[event_waiting].dateTo = (new Date(res[event_waiting].dateTo * 1000)).toString();

                          if (res[event_waiting].repeat_end != 0) {
                            res[event_waiting].repeat_end = (new Date(res[event_waiting].repeat_end * 1000)).toString();
                          }

                        }
                        this.waiting_events = res;
                      }
                    })
                for (let event in res) {
                  res[event].dateFrom = (new Date(res[event].dateFrom * 1000)).toString();
                  res[event].dateTo = (new Date(res[event].dateTo * 1000)).toString();

                  if (res[event].repeat_end != 0) {
                    res[event].repeat_end = (new Date(res[event].repeat_end * 1000)).toString();
                  }

                }
                return res;
              })
              .then(res => {
                this.events = res;
                this.loading = false;
              })
              .then(res => {
                this.setEventDefaultOptions();
                this.loadEventsInCalendar();
                this.settings.calendar_format = localStorage.calendar_format ? localStorage.calendar_format : 'month';
              })
        })
  }
  ,
  watch: {
    format_day(newVal) {
      if (!this.loading) {
        this.changePeriod();
      }
    }
    ,

    'format_week.day'(newVal) {
      if (!this.loading) {
        this.changePeriod();
      }
    }
    ,
    dateEventTo(newVal) {
      this.newEvent.dateTo = new Date(newVal);
    }
    ,
    timeEventTo(newVal) {
      let res = new Date(this.newEvent.dateTo);
      res.setHours(newVal.hours, newVal.minutes, 0, 0);
      this.newEvent.dateTo = res;
    }
    ,

    dateEventFrom(newVal) {
      this.newEvent.dateFrom = new Date(newVal);
    }
    ,
    timeEventFrom(newVal) {
      let res = new Date(this.newEvent.dateFrom);
      res.setHours(newVal.hours, newVal.minutes, 0, 0);
      this.newEvent.dateFrom = res;
    }
    ,

    'newMonth.month'(newVal) {
      this.month = this.newMonth.month;
      this.year = this.newMonth.year;
      this.changePeriod();
    }
    ,
    'newMonth.year'(newVal) {
      this.month = this.newMonth.month;
      this.year = this.newMonth.year;
      this.changePeriod();
    }
    ,
  }

}
</script>
<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#app {
  font-family: "Roboto Light", sans-serif;
  color: #20273e;
  background-color: #fbfaff;
}

.calendar {
  padding: 20px;
  border-radius: 10px;
}

.calendar_header {
  display: flex;
  justify-content: flex-start;
  text-align: center;
  position: sticky;
  top: 63px;
  z-index: 8;
  background-color: white;
  box-shadow: 0px 0px 5px -3px;
}

.calendar_body_week {
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
  max-width: 905px;
}

.calendar_body_week_day {
  height: 140px;
  width: 140px;
  background-color: #ffffff;
  color: black;
  border: 1px solid #ededed;
  cursor: pointer;
  transition: all .3s;
  padding: 2px;
}

.calendar_body_week_day_event {
  position: absolute;
  font-size: 15px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  white-space: nowrap;
  transition: all .3s;
}

.calendar_body_week_day.dayDisabled .calendar_body_week_day_event, .calendar_body_week.dayDisabled .calendar_body_week_day_event, .calendar_body_week_slot.dayDisabled .calendar_body_week_day_event {
  pointer-events: none;
}


.calendar_body_week_day.dayDisabled {
  border: 1px solid red;
}

.calendar_body_week_day_event.clone {
  display: none;
  pointer-events: none;
}

.calendar_body_week_day_event_text {
  text-overflow: ellipsis;
  overflow: hidden;
  height: 17px;
}

.calendar_body_week_day_event_time_start {
  margin-right: 5px;
}

.calendar_body_week_day_event_time_start, .calendar_body_week_day_event_time_end {
  text-overflow: ellipsis;
  overflow: hidden;
}

.calendar_body_week_day_event_resize {
  width: 10px;
  height: 100%;
  position: absolute;
  cursor: e-resize;
}

.calendar_body_week_day_event_resize.left {
  left: 0;
}

.calendar_body_week_day_event_resize.right {
  right: 0;
}

.calendar_body_week_day_event_resize_week {
  width: 100%;
  height: 10px;
  position: absolute;
  cursor: n-resize;
}

.calendar_body_week_day_event_resize_week.top {
  top: 0;
}

.calendar_body_week_day_event_resize_week.bottom {
  bottom: 0;
}


.calendar_body_week_day_event_content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 10px;
  height: 100%;
  align-items: center;
}

.calendar_body_week_day_event.preview, .waiting_list_elem.preview {
  opacity: 0.5;
  z-index: 9999;
}

.calendar_body_week_day.weekDisabled .calendar_body_week_day_event {
  pointer-events: none;
}

.modal_date_pick {
  display: flex;
  justify-content: space-between;
}


.select_period {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
}

.select_period_btn {
  border-radius: 5px;
  background-color: white;
  color: black;
  padding: 8px;
  cursor: pointer;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.select_period .dp__input_icon_pad {
  padding: 4px 4px 4px 35px;
}

.select_period_btn svg {
  width: 9px;
}

.calendar_body_week_day.today {
  border: 1px solid #3B9ED4;
}

.modal_close {
  cursor: pointer;
  width: 40px;
  position: absolute;
  height: 40px;
  right: 0;
  top: 0;
}

.arrow-left {
  transform: rotate(180deg);
}

.input-slot-image {
  width: 20px;
  position: absolute;
  left: -10px;
  top: -7px;
}


.show_more {
  position: absolute;
  bottom: 0;
  cursor: pointer;
  color: #a1a1a1;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 13px;
}

.calendar_body_week_day_more {
  height: 100%;
  position: absolute;
  background-color: white;
  top: 0;
  margin-left: -3px;
  padding: 4px 4px;
  overflow: auto;
  max-width: 180px;
  box-shadow: 0px 0px 30px -13px black;
  border-radius: 10px;
  z-index: 3;
}

.calendar_body_week_day_event.in_modal {
  position: relative;
  margin-bottom: 5px;
}

.calendar_body_week_day_event.in_modal .calendar_body_week_day_event_content {
  padding-left: 2px;
}

.background_show_more {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
  left: 0;
  top: 0;
}

.calendar_body_week_day_event_content.all_day {
  justify-content: center;
}

.control_panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 905px;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px -2px;
  margin-bottom: 10px;
  border-radius: 10px;
  padding-right: 10px;
  position: sticky;
  top: 10px;
  z-index: 9;
}

.control_panel .control_panel_menu {
  width: 15px;
}

.control_panel .control_panel_menu:after {
  content: '\2807';
  font-size: 30px;
  color: black;
  cursor: pointer;
}

.control_panel_menu:hover :after {
  color: #3B9ED4;
}

.control_panel_settings svg path:hover {
  fill: #3B9ED4;
}


.control_panel_settings {
  display: flex;
  width: 25px;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.control_panel_settings svg {
  cursor: pointer;
}

.control_panel_settings svg path {
  fill: black;
  transition: all .3s;
}

.calendar_content {
  display: flex;
}

.calendar_content_waiting_list {
  margin-left: 50px;
  max-width: 930px;
}

.calendar_content_background {
  background-color: gainsboro;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0px 0px 4px -2px;
  max-width: 905px;
}

.calendar_header div {
  padding: 20px 10px 5px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.control_panel_btns {
  display: flex;
  align-items: center;
}

.control_panel_format {
  display: flex;
  margin-left: 10px;
}

.control_panel_format_btn {
  padding: 7px;
  margin-right: 5px;
  cursor: pointer;
  transition: all .3s;
  font-weight: normal;
  border: 0;
  border-radius: 5px;
  text-align: center;
  background-color: white;
  color: black;
  margin-left: 5px;
}

.control_panel_format_btn:hover {
  background-color: #7cb4d3;
  color: white;
}

.control_panel_format_btn.active {
  background-color: #3B9ED4;
  color: #fff;
}


.waiting_list {
  min-height: 400px;
  padding: 0 30px;
  min-width: 300px;
  width: 100%;
  border-radius: 10px;
  position: sticky;
  top: 20px;
  max-height: 740px;
  overflow: auto;
}

.waiting_list_elem {
  background-color: navajowhite;
  padding: 10px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: move;
  border-radius: 2px;
}

.waiting_list_elem_content p:nth-of-type(1) {
  font-size: 16px;
  font-weight: bold;
}

.waiting_list_elem_content p:nth-of-type(2) {
  font-size: 12px;
  font-weight: 100;
  margin: 5px 0;
}

.waiting_list_elem_content p:nth-of-type(3) {
  font-size: 13px;
  font-weight: 100;
  text-transform: uppercase;
  color: #adadad;
}

.waiting_list_elem_drag_img img {
  width: 15px;
  opacity: .5;
}

.waiting_list h3 {
  margin-bottom: 5px;
  text-align: center;
}

.calendar_body.format_week {
  display: flex;
}

.calendar_body.format_week .calendar_body_week {
  display: flex;
  flex-direction: column;
}

.calendar_body.format_day {
  display: flex;
}

.calendar_body.format_day .calendar_body_week {
  flex-direction: column;
  width: 100%;
}

.calendar_body.format_day .calendar_body_week_slot {
  width: 100%;
}

.calendar_body {
  max-width: 953px;
}

.calendar_body_week_slot {
  width: 130px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: white;
  border: 1px solid #eeeeee;
  height: 51px;
}

.calendar_body_week_slot .calendar_body_week_slot_half:nth-of-type(1) {
  border-bottom: 1px dashed silver;
  width: 100%;
}

.calendar_body_week_slot_half {
  height: 50px;
}

.calendar_body_time_col_elem {
  width: 50px;
  height: 102px;
  border: 1px solid #e9e9f0;
  color: #a4a4a4;
  padding: 10px 3px;
  background-color: white;
}

.container_select_period_btn {
  display: flex;
}


.control_panel_menu {
  display: none;
}

.mini_modal_select_format {
  position: fixed;
  top: 50px;
  right: 20px;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 0px 6px -3px;
}

.mini_modal_select_format .control_panel_format_btn {
  margin-top: 5px;
}

.calendar_header .calendar_header_day {
  height: 60px;
  display: flex;
  align-items: center;
  padding: 10px 10px 5px 10px;
}

@media (max-width: 1290px) {
  .calendar_content {
    display: block;
  }

  .calendar_content_waiting_list {
    margin: 20px 10px;
  }

  .calendar_content_waiting_list.show_waiting_list {
    position: sticky;
    bottom: 10px;
    margin: 20px 0;
  }

  .calendar_content_waiting_list.show_waiting_list h3 {
    display: none;
  }

  .calendar_content_waiting_list.show_waiting_list .waiting_list {
    min-height: unset;
    display: flex;
    align-items: center;
    overflow: scroll;
    overflow-y: hidden;
    padding: 0;
  }

  .calendar_content_waiting_list {
    margin: 20px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .calendar_content_waiting_list.show_waiting_list .waiting_list .waiting_list_elem {
    margin-right: 10px;
  }

  .control_panel_format {
    display: none;
  }

  .control_panel_menu {
    display: block;
  }
}

.calendar_body_week_slot .calendar_body_week_day_event_content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.alert {
  position: fixed;
  right: 10px;
  bottom: 60px;
  width: 230px;
  padding: 10px;
  z-index: 999;
  text-align: start;
}

.alert div {
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.alert .success {
  background: rgba(26, 255, 0, 0.4);
}

.alert .error {
  background: rgba(255, 0, 0, 0.4);
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.control_panel_format_btn_mobile {
  display: none;
}
@media (max-width: 770px) {
  .control_panel_format_btn_pc{
    display: none;
  }
  .control_panel_format_btn_mobile {
    display: block;
  }

  .calendar_header {
    font-size: 11px;
  }

  .calendar_header_date {
    font-size: 11px;
  }

  .calendar_header div {
    padding: 5px;
  }

  .calendar_body_week_day_more {
    width: 140px!important;
    right: auto;
    left: auto;
    position: relative;
    top: -20px;
    height: 100%;
  }

}
@media (max-width: 450px) {
  .show_more {
    position: absolute;
    bottom: 0;
    cursor: pointer;
    color: #a1a1a1;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 10px;
  }
}


.calendar_body_week_day_event {
  text-overflow: ellipsis;
  overflow: hidden;
}

.vacp-range-input-label.vacp-range-input-label--alpha {
  display: none;
}

.waiting_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.waiting_header .control_panel_format_btn {
  box-shadow: 0px 0px 7px -4px;
}

.calendar_body_week_day_more_header {
  position: sticky;
  top: -5px;
  z-index: 9;
  background-color: #e4e5eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calendar_body_week_day_more_header div {
  display: flex;
  align-items: center;
  text-decoration: underline;
}

.calendar_body_week_day_more svg {
  width: 22px;
}
</style>
