<template>


  <div @click="checkUpdate();" class="background_modal" v-if="!loadingListUsers">
    <div class="modal" @click.stop="show_list_of_user = false">
      <div class="modal_close" @click="checkUpdate()">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <path d="M16 8L8 16M8.00001 8L16 16" stroke="#000000" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"></path>
          </g>
        </svg>
      </div>
      <h2>Редактировать событие</h2>

      <div class="modal_main_info">

        <div class="modal_main_info_elem">
          <label>
            <span>Название</span>
            <input type="text" placeholder="Название события" v-model="this.editEvent.text">
          </label>
        </div>

        <div class="modal_main_info_elem">
          <label>
            <span>Пользователь</span>
            <input type="text" placeholder="Пользователь" v-model="this.editEvent.user_login_text"
                   @focus="show_list_of_user = true"
                   @click.stop
            >
            <div class="select_user"
                 v-if="show_list_of_user && !this.editEvent.userID && getUsersMatches().length != 0">
              <span class="select_user_elem" v-for="user in getUsersMatches()"
                    @click.stop="this.change_sup_click = true; this.editEvent.user_login_text = user.user_login; this.editEvent.userID = user.user_id">{{
                  user.user_login
                }}</span>
            </div>
            <div class="select_user" v-if="getUsersMatches().length == 0 && show_list_of_user">
              <span class="select_user_elem not_active">Не найдено, пользователь добавится автоматически</span>
            </div>
          </label>
        </div>

        <div class="modal_main_info_elem">
          <label>
            <span>Группа</span>
            <select v-model="this.editEvent.event_group">
              <option :value="`${elem.group_id}`" v-for="elem in group">{{ elem.group_name }}</option>
            </select>
          </label>
        </div>

        <div class="modal_main_info_elem">
          <label class="modal_main_info_elem_check">
            <span>Весь день</span>
            <input type="checkbox" placeholder="Название события" true-value="1" false-value="0"
                   v-model="this.editEvent.all_day">
          </label>
        </div>

        <div class="modal_main_info_elem" v-if="this.editEvent.repeat">
          <label class="modal_main_info_elem_check">
            <span>Нельзя изменить дату у события-повторения</span>
          </label>
        </div>

        <div class="modal_main_info_elem" v-show="this.editEvent.all_day == '0' && !this.editEvent.repeat">
          <div class="modal_date_pick">
            <div class="modal_date_pick_col">
              <span>От</span>
              <div class="modal_date_pick_elem">
                <VueDatePicker v-model="this.editEvent.dateFrom" :min-date="new Date()" placeholder="Date to"
                               class="only_clear" text-input :is-24="timeFormatBool" teleport-center/>
                <VueDatePicker v-model="dateEventFrom" :min-date="new Date()" class="svg_disabled"
                               :enable-time-picker="false" :is-24="timeFormatBool" teleport-center/>
                <VueDatePicker v-model="timeEventFrom" time-picker class="svg_disabled" :is-24="timeFormatBool"
                               teleport-center>
                  <template #input-icon>
                    <img class="input-slot-image" src="@/assets/clock.png"/>
                  </template>
                </VueDatePicker>
              </div>
            </div>
            <div class="modal_date_pick_col">
              <span>До</span>
              <div class="modal_date_pick_elem">
                <VueDatePicker v-model="this.editEvent.dateTo" :min-date="new Date(this.editEvent.dateFrom)"
                               placeholder="Date to" class="only_clear" text-input :is-24="timeFormatBool"
                               teleport-center/>
                <VueDatePicker v-model="dateEventTo" :min-date="new Date(this.editEvent.dateFrom)"
                               class="svg_disabled" :enable-time-picker="false" :is-24="timeFormatBool"
                               teleport-center/>
                <VueDatePicker v-model="timeEventTo" time-picker class="svg_disabled" :is-24="timeFormatBool"
                               teleport-center>
                  <template #input-icon>
                    <img class="input-slot-image" src="@/assets/clock.png"/>
                  </template>
                </VueDatePicker>
              </div>
            </div>
          </div>
        </div>

        <div class="modal_main_info_elem error" v-if="checkValidTwoDate()">
          <span>Неверно указана дата</span>
        </div>

        <div class="modal_main_info_elem">
          <label>
            <span>Описание</span>
            <textarea placeholder="Описание" v-model="this.editEvent.description"></textarea>
          </label>
        </div>

        <div class="modal_main_info_elem">
          <label>
            <span>Локация</span>
            <input type="text" placeholder="Локация" v-model="this.editEvent.location">
          </label>
        </div>

        <div class="modal_main_info_elem">
          <label>
            <span>Добавить файлы</span>
            <input type="file" name="filefield" multiple="multiple" v-on:change="uploadFiles($event.target.files)">
          </label>
        </div>

        <div class="modal_main_info_elem" v-if="this.editEvent.files !== '[]'">
          <label>
            <span>Файлы</span>
            <span v-for="(file, index) in this.editEvent.files" class="info_file">
              <svg @click="deleteFile(this.editEvent.ID, file, index)" viewBox="0 0 24 24" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <path d="M16 8L8 16M8.00001 8L16 16" stroke="#000000" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </g>
              </svg>
              <a :href="`files/${file}`" target="_blank">{{ file }}</a>
            </span>
          </label>
        </div>

        <div class="modal_main_info_elem urls">
          <label>
            <span>Ссылки</span>

            <div class="urls_elem">
              <input type="text" placeholder="Ссылка"
                     v-model="this.editEvent.url_1">
            </div>

            <div class="urls_elem" v-if="show_url_2">
              <svg @click="hideUrl('show_url_2', 'url_2')" viewBox="0 0 24 24" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <path d="M16 8L8 16M8.00001 8L16 16" stroke="#000000" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </g>
              </svg>
              <input type="text" placeholder="Ссылка"
                     v-model="this.editEvent.url_2">
            </div>

            <div class="urls_elem" v-if="show_url_3">
              <svg @click="hideUrl('show_url_3', 'url_3')" viewBox="0 0 24 24" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <path d="M16 8L8 16M8.00001 8L16 16" stroke="#000000" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </g>
              </svg>
              <input type="text" placeholder="Ссылка"
                     v-model="this.editEvent.url_3">
            </div>

            <div class="urls_elem" v-if="show_url_4">
              <svg @click="hideUrl('show_url_4', 'url_4')" viewBox="0 0 24 24" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <path d="M16 8L8 16M8.00001 8L16 16" stroke="#000000" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </g>
              </svg>
              <input type="text" placeholder="Ссылка"
                     v-model="this.editEvent.url_4">
            </div>

            <div class="urls_elem" v-if="show_url_5">
              <svg @click="hideUrl('show_url_5', 'url_5')" viewBox="0 0 24 24" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <path d="M16 8L8 16M8.00001 8L16 16" stroke="#000000" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </g>
              </svg>
              <input type="text" placeholder="Ссылка"
                     v-model="this.editEvent.url_5">
            </div>

            <div class="url_add_elem" v-if="!show_url_5 || !show_url_4 || !show_url_3 || !show_url_2"
                 @click="showUrls()">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus"
                   viewBox="0 0 16 16">
                <path
                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
              </svg>
            </div>

          </label>
        </div>


        <div class="modal_main_info_elem color_pick">
          <div class="color_pick_elem">
            <label @click="setSelectColor()">
              <span>Цвет</span>
              <div class="modal_main_info_elem_selected_color"
                   :style="`background-color:${this.editEvent.color};`"></div>
            </label>

            <div v-if="selectColor" class="select_color left">
              <ColorPicker @color-change="updateColor" :visible-formats="['hex']"/>
              <div class="select_color_btn">
                <div @click="setSelectColor()">Отмена</div>
                <div @click="saveColor()">Сохранить</div>
              </div>
            </div>
          </div>
          <div class="color_pick_elem">
            <label @click="setSelectTextColor()">
              <span>Цвет текста</span>
              <div class="modal_main_info_elem_selected_color"
                   :style="`background-color:${this.editEvent.text_color};`"></div>
            </label>

            <div v-if="selectTextColor" class="select_color right">
              <ColorPicker @color-change="updateTextColor" :visible-formats="['hex']"/>
              <div class="select_color_btn">
                <div @click="setSelectTextColor()">Отмена</div>
                <div @click="saveTextColor()">Сохранить</div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal_main_info_elem">
          <label>
            <span>Повторять</span>
            <select v-model="this.editEvent.event_repeat">
              <option value="0">Никогда</option>
              <option value="1">Ежедневно</option>
              <option value="2">Еженедельно</option>
              <option value="3">Ежемесячно</option>
            </select>
          </label>
        </div>

        <div class="modal_main_info_elem" v-if="this.editEvent.event_repeat != 0">
          <label>
            <div style="display: flex">
              <span v-if="this.editEvent.event_repeat == 1">Повторять каждый {{
                  this.editEvent.repeat_every_days
                }} </span>
              <span style="margin-left: 5px" v-if="this.editEvent.event_repeat == 1"> день</span>
              <span v-if="this.editEvent.event_repeat == 2">Повторять каждую {{
                  this.editEvent.repeat_every_days
                }} </span>
              <span style="margin-left: 5px" v-if="this.editEvent.event_repeat == 2"> неделю</span>
              <span v-if="this.editEvent.event_repeat == 3">Повторять каждый {{
                  this.editEvent.repeat_every_days
                }} </span>
              <span style="margin-left: 5px" v-if="this.editEvent.event_repeat == 3"> месяц</span>
            </div>
            <input type="number" min="1" max="999" v-model="this.editEvent.repeat_every_days">
          </label>
        </div>

        <div class="modal_main_info_elem more_elems" v-if="this.editEvent.event_repeat != 0">
          <label>
            <span>Повторять</span>
            <select v-model="repeat_type">
              <option value="раз">Количество</option>
              <option value="до">Дата</option>
            </select>
          </label>
          <div class="modal_date_pick_col">
            <label v-if="repeat_type === 'до'">
              <span>До</span>
              <div class="modal_date_pick_elem">
                <VueDatePicker v-model="this.editEvent.repeat_end" :min-date="new Date(this.editEvent.dateTo)"
                               placeholder="Date to"
                               class="only_clear" text-input :is-24="timeFormatBool" teleport-center
                               :enable-time-picker="false"/>
              </div>
            </label>
            <label v-if="repeat_type === 'раз'">
              <span>Количество: {{ this.editEvent.count_repeat }}</span>
              <input type="number" min="1" max="999" v-model="this.editEvent.count_repeat">
            </label>
          </div>
        </div>
        <div class="modal_main_info_elem error"
             v-if="checkValidDateEnd()">
          <span>Неверно указана дата</span>
        </div>
        <div class="modal_main_info_elem error"
             v-if="!this.editEvent.count_repeat && this.editEvent.event_repeat != 0 && repeat_type === 'раз'">
          <span>Неверно указано количество</span>
        </div>

        <div class="modal_main_info_elem error"
             v-if="checkValidDateRepeat()">
          <span>Длительность "От" и "До" не может быть больше "Повторять каждый N день"</span>
        </div>

        <div class="modal_main_info_elem">
          <label>
            <span>Привязать к</span>
            <select v-model="this.editEvent.bind">
              <option :value="`${event.ID}`" v-for="(event, index) in this.list_of_events">{{ event.text }}</option>
            </select>

          </label>
        </div>

        <div class="modal_main_info_elem" v-if="this.editEvent.bind > 0">
          <label>
            <span>Начать после завершения через {{ this.editEvent.start_after_days }} день</span>
            <input type="number" min="1" max="999" v-model="this.editEvent.start_after_days">
          </label>
        </div>

        <div class="modal_main_info_elem" v-if="this.editEvent.binds_events.length">
          <label>
            <span>Привязанные к этому событию</span>
            <div class="modal_main_info_elem_binds_elem" v-for="elem in this.editEvent.binds_events">
              <svg @click="deleteBind(elem.ID)" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <path d="M16 8L8 16M8.00001 8L16 16" stroke="#000000" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </g>
              </svg>
              {{ elem.text }}
            </div>


          </label>
        </div>


      </div>

      <div class="modal_event_btns">
        <button v-if="this.editEvent.transfer_waiting" @click="this.$emit('cancelTransfer')">Отмена</button>
        <button v-if="this.editEvent.transfer_waiting" @click="this.$emit('deleteEvent', this.editEvent.ID, true)">
          Удалить
        </button>
        <button v-if="this.editEvent.transfer_waiting" @click="this.$emit('saveEvent', this.editEvent.ID, true)"
                :class="{error: checkValidTwoDate() || checkValidDateRepeat() || checkValidDateEnd() || (!this.editEvent.count_repeat && this.editEvent.event_repeat != 0 && repeat_type === 'раз')}">
          Сохранить
        </button>

        <button v-if="!this.editEvent.transfer_waiting" @click="this.$emit('deleteEvent', this.editEvent.ID, false)">
          Удалить
        </button>
        <button v-if="!this.editEvent.transfer_waiting" @click="this.$emit('saveEvent', this.editEvent.ID, false)"
                :class="{error: checkValidTwoDate() || checkValidDateRepeat() || checkValidDateEnd() || (!this.editEvent.count_repeat && this.editEvent.event_repeat != 0 && repeat_type === 'раз')}">
          Сохранить
        </button>

      </div>
    </div>
  </div>

  <div class="accept_close" v-if="this.acceptChange">
    <div class="modal_close" @click="this.setAcceptChange(false)">
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path d="M16 8L8 16M8.00001 8L16 16" stroke="#000000" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round"></path>
        </g>
      </svg>
    </div>
    <h2>Сохранить изменения?</h2>
    <div class="modal_control">
      <button @click="this.setAcceptChange(false); this.setModalType(null);">Нет</button>

      <button v-if="this.editEvent.transfer_waiting"
              @click="this.setAcceptChange(false); this.$emit('saveEvent', this.editEvent.ID, true)"
              :class="{error: checkValidTwoDate() || checkValidDateRepeat() || checkValidDateEnd()}">
        Сохранить
      </button>

      <button v-if="!this.editEvent.transfer_waiting"
              @click="this.setAcceptChange(false); this.$emit('saveEvent', this.editEvent.ID, false)"
              :class="{error: checkValidTwoDate() || checkValidDateRepeat() || checkValidDateEnd()}">
        Сохранить
      </button>

    </div>
  </div>

</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import axios from 'axios';
import {ColorPicker} from 'vue-accessible-color-picker'

export default {
  emits: ["cancelTransfer", "saveEvent", "deleteEvent"],
  components: {
    VueDatePicker,
    ColorPicker,
  },
  data() {
    return {
      show_url_2: false,
      show_url_3: false,
      show_url_4: false,
      show_url_5: false,

      list_of_events: [],
      repeat_type: 'раз',
      show_list_of_user: false,
      change_sup_click: false,

      select_color: '#f80b',
      selectColor: false,
      selectTextColor: false,

      dateEventFrom: null,
      dateEventEndRepeat: null,
      timeEventFrom: null,
      timeEventEndRepeat: null,
      dateEventTo: null,
      timeEventTo: null,

      users: [],
      group: null,

      loadingListUsers: true,
    }
  },
  mounted() {
    this.getListUsers()
        .then(res => {
          this.getEventForList()
              .then(res => {
                this.list_of_events = res;
              })
          this.users = res;
          if (this.editEvent.repeat_end != 0) {
            this.repeat_type = 'до';
          }
          for (let i in this.users) {
            if (this.users[i].user_id == this.editEvent.userID) {
              this.editEvent.user_login_text = this.users[i].user_login;
            }
          }
          return true;
        })
        .then(res => {
          if (this.editEvent.url_2) {
            this.show_url_2 = true;
          }
          if (this.editEvent.url_3) {
            this.show_url_3 = true;
          }
          if (this.editEvent.url_4) {
            this.show_url_4 = true;
          }
          if (this.editEvent.url_5) {
            this.show_url_5 = true;
          }
          return this.getListGroup();
        })
        .then(res => {
          this.group = res;
          return true;
        })
        .then(res => {
          this.loadingListUsers = false;
        })
  },
  methods: {
    hideUrl(show, value) {
      this[show] = false;
      this.editEvent[value] = '';
    },
    showUrls() {
      if (!this.show_url_2) {
        this.show_url_2 = true;
      } else if (!this.show_url_3) {
        this.show_url_3 = true;
      } else if (!this.show_url_4) {
        this.show_url_4 = true;
      } else if (!this.show_url_5) {
        this.show_url_5 = true;
      }
    },
    addAlert(text, status) {
      let alertID = Math.floor(Math.random() * 1000);
      this.setGlobalAlerts({type: status, text: text, id: alertID});
      setTimeout(() => {
        this.deleteGlobalAlert(alertID);
      }, "5000");
    },
    async deleteFile(eventID, file, index) {
      let url;
      if (this.editEvent.transfer_waiting) {
        url = 'property.php?deleteFile&waitingList';
      } else {
        url = 'property.php?deleteFile';
      }
      try {
        const response = await axios.post(
            url,
            {
              eventID: eventID,
              file: file,
              headers: {
                'Content-Type': 'application/json',
              },
            },
        )

        if (response.data.status == 'success') {
          this.addAlert('Файл удалён', 'success');
          this.editEvent.files.splice(index, 1)
        } else {
          this.addAlert('Ошибка при удалении файла', 'error');
        }
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async deleteBind(eventID) {
      try {
        const response = await axios.post(
            'property.php?deleteBind&eventID=' + eventID,
            {
              headers: {
                'Content-Type': 'application/json',
              },
            },
        )

        if (response.data.status == 'success') {
          for (let i in this.editEvent.binds_events) {
            if (this.editEvent.binds_events[i].ID == eventID) {
              this.editEvent.binds_events.splice(i, 1);
            }
          }
        }

      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async getEventForList() {
      try {
        const response = await axios.post(
            'property.php?getEventForList',
            {
              headers: {
                'Content-Type': 'application/json',
              },
            },
        )
        return response.data;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    getDuration(dateFrom, dateTo) {
      let one = new Date(new Date(dateFrom).getFullYear(), new Date(dateFrom).getMonth(), new Date(dateFrom).getDate());
      let two = new Date(new Date(dateTo).getFullYear(), new Date(dateTo).getMonth(), new Date(dateTo).getDate());
      let millisecondsPerDay = 1000 * 60 * 60 * 24;
      let millisBetween = two.getTime() - one.getTime();
      let days = millisBetween / millisecondsPerDay;
      return Math.floor(days) + 1;
    },
    checkValidDateEnd() {
      if (this.repeat_type == 'до') {
        if (this.dateCompare(new Date(this.editEvent.dateTo), '>', new Date(this.editEvent.repeat_end))) {
          return true;
        } else {
          if (!this.editEvent.repeat_end) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    },
    checkValidDateRepeat() {
      if (this.editEvent.event_repeat != 0) {
        if (this.getDuration(this.editEvent.dateFrom, this.editEvent.dateTo) > this.editEvent.repeat_every_days) {
          return true;
        } else {
          if (!this.editEvent.dateFrom || !this.editEvent.dateTo) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    },
    checkValidTwoDate() {
      if (this.dateCompare(new Date(this.editEvent.dateFrom), '>', new Date(this.editEvent.dateTo)) && !this.dateCompare(new Date(this.editEvent.dateFrom), '===', new Date(this.editEvent.dateTo))) {
        return true;
      } else {
        if (!this.editEvent.dateFrom || !this.editEvent.dateTo) {
          return true;
        } else {
          return false;
        }
      }
    },
    dateCompare(date1, sign, date2) {
      let day1 = date1.getDate();
      let mon1 = date1.getMonth();
      let year1 = date1.getFullYear();
      let day2 = date2.getDate();
      let mon2 = date2.getMonth();
      let year2 = date2.getFullYear();
      if (sign === '===') {
        if (day1 === day2 && mon1 === mon2 && year1 === year2) return true;
        else return false;
      } else if (sign === '>') {
        if (year1 > year2) return true;
        else if (year1 === year2 && mon1 > mon2) return true;
        else if (year1 === year2 && mon1 === mon2 && day1 > day2) return true;
        else return false;
      }
    },
    getUsersMatches() {
      if (this.editEvent.user_login_text) {
        if (!this.users) {
          return [];
        } else {
          return this.users.filter(
              (elem) => elem.user_login.toLowerCase().includes(this.editEvent.user_login_text.toLowerCase())
          )
        }
      } else {
        if (!this.users) {
          return [];
        } else {
          return this.users;
        }
      }
    },
    updateColor(color) {
      this.select_color = color.colors.hex;
    },
    updateTextColor(color) {
      this.select_text_color = color.colors.hex;
    },
    saveColor() {
      this.editEvent.color = this.select_color;
      this.setSelectColor();
    },

    saveTextColor() {
      this.editEvent.text_color = this.select_text_color;
      this.setSelectTextColor();
    },
    setSelectColor() {
      if (this.selectColor) {
        this.selectColor = false;
      } else {
        this.selectColor = true;
      }
    },
    setSelectTextColor() {
      if (this.selectTextColor) {
        this.selectTextColor = false;
      } else {
        this.selectTextColor = true;
      }
    },

    uploadFiles(files) {
      for (let file in files) {
        if (typeof files[file] !== 'object') continue;
        if (files[file].size > 10485760) {
          alert('Размер файла не должен превышать 10мб. "' + files[file].size + '" не был добавлен')
          continue;
        } else {
          this.editEvent.uploadFiles.push(files[file])
        }
      }
    },
    async getListUsers() {
      try {
        const response = await axios.post(
            'property.php?getListUsers',
            {
              headers: {
                'Content-Type': 'application/json',
              },
            },
        )
        return response.data;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async getListGroup() {
      try {
        const response = await axios.post(
            'property.php?getListGroup',
            {
              headers: {
                'Content-Type': 'application/json',
              },
            },
        )
        return response.data;
      } catch (e) {
        console.log(e);
        return false;
      }
    },


    saveSettings(close = false) {
      this.setSettings(this.local_settings);
      this.loadSettingsInCoockie();
      if (close) {
        this.setModalType(null);
      }
    },
    checkUpdate() {
      if (!this.selectColor) {
        if (this.updateData) {
          this.setAcceptChange(true);
        } else {
          this.setModalType(null);
          if (this.editEvent.transfer_waiting) {
            this.$emit('cancelTransfer');
          }
        }
      }
    },
    ...mapMutations({
      setSettings: 'setSettings',
      setModalType: 'setModalType',
      setAcceptChange: 'setAcceptChange',
      setUpdateData: 'setUpdateData',
      deleteGlobalAlert: 'deleteGlobalAlert',
      setGlobalAlerts: 'setGlobalAlerts',
    }),
    ...mapActions({
      //saveFavorites: 'saveFavorites',
    }),
  },
  computed: {
    timeFormatBool() {
      if (this.settings.time_format == 24) {
        return true;
      } else {
        return false;
      }
    },
    ...mapState({
      modalType: state => state.modalType,
      editEvent: state => state.editEvent,
      acceptChange: state => state.acceptChange,
      settings: state => state.settings,
      updateData: state => state.updateData,
    }),
  },
  watch: {
    'editEvent.count_repeat'(newVal, oldVal) {
      if (newVal < 0) {
        this.editEvent.count_repeat = oldVal;
      }
    },
    'editEvent.user_login_text'(newVal) {
      if (!this.change_sup_click) {
        this.editEvent.userID = 0;
      }
      this.change_sup_click = false;
    },
    repeat_type(newVal) {
      if (newVal === 'раз') {
        this.editEvent.repeat_end = 0;
      } else {
        this.editEvent.count_repeat = 0;
      }
    },
    editEvent: {
      handler(newValue, oldValue) {
        if (!this.loadingListUsers) {
          this.setUpdateData(true);
        }
      },
      deep: true
    },
    dateEventTo(newVal) {
      this.editEvent.dateTo = new Date(newVal);
    },
    timeEventTo(newVal) {
      let res = new Date(this.editEvent.dateTo);
      res.setHours(newVal.hours, newVal.minutes);
      this.editEvent.dateTo = res;
    },

    dateEventFrom(newVal) {
      this.editEvent.dateFrom = new Date(newVal);
    },
    dateEventEndRepeat(newVal) {
      this.editEvent.repeat_end = new Date(newVal);
    },
    timeEventFrom(newVal) {
      let res = new Date(this.editEvent.dateFrom);
      res.setHours(newVal.hours, newVal.minutes);
      this.editEvent.dateFrom = res;
    },
    timeEventEndRepeat(newVal) {
      let res = new Date(this.editEvent.repeat_end);
      res.setHours(newVal.hours, newVal.minutes);
      this.editEvent.repeat_end = res;
    },
  },
}
</script>

<style>
.modal {
  min-width: 310px;
  min-height: 400px;
  background-color: white;
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
  padding: 10px 30px;
  flex-direction: column;
  align-items: center;
  max-width: 550px;
  width: 90%;
}

.modal_content {
  width: 100%;
}

.modal_content_elem p {
  font-size: 13px;
  color: #7b7b7b;
  font-weight: bold;
}

.modal_content_elem {
  margin-top: 20px;
}

.modal_content_elem select {
  width: 100%;
  margin-top: 5px;
  font-size: 16px;
  padding: 5px;
}

.modal_control {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.modal_control button {
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
}

.background_modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #00000061;
  left: 0;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accept_close {
  left: 0;
  position: fixed;
  background-color: white;
  box-shadow: 0px 0px 10px 0px;
  height: 150px;
  width: 250px;
  display: flex;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  z-index: 999;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.accept_close .modal_control {
  display: flex;
  align-items: center;
  justify-content: center;
}

.accept_close .modal_control button {
  margin-left: 5px;
  margin-right: 5px;
}

.modal_close_btn {
  position: absolute;
  right: 0;
  width: 40px;
  top: 0;
}

.modal_main_info {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  overflow: scroll;
  max-height: 600px;
  overflow-x: hidden;
  margin-bottom: 20px;
}

.modal_main_info_elem {
  margin-bottom: 20px;
  width: 100%;
}

.modal_main_info_elem label {
  display: flex;
  flex-direction: column;
}

.modal_main_info_elem label span {
  font-size: 13px;
  color: gray;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.modal_main_info_elem label input, .modal_main_info_elem label textarea, .modal_main_info_elem label select {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e2e2e2;
}

.modal_main_info_elem label input:focus, .modal_main_info_elem label textarea:focus, .modal_main_info_elem label select:focus {
  border: 1px solid #e2e2e2;
  box-shadow: 0 0 6px 0 #037cae;
  outline: none;
}

.modal_main_info_elem_selected_color {
  width: 100%;
  height: 30px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px -1px;
}

.modal_date_pick_elem .only_clear svg.dp__input_icon {
  display: none;
}

.modal_date_pick_elem .only_clear .dp__input_icon_pad {
  padding-left: 5px;
  border-radius: 0px;
}

.modal_date_pick_elem {
  display: flex;
  max-width: 230px;
  align-items: center;
}

.svg_disabled svg.dp__clear_icon, .svg_disabled input {
  display: none;
}

.svg_disabled {
  width: 70px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid #dadada;
  max-width: 30px;
}

.svg_disabled svg.dp__input_icon {
  position: absolute;
  left: -8px;
  padding: 0;
}

.dp__clear_icon {
  background-color: white;
  padding-left: 5px;
  padding-right: 0;
  margin-right: 5px;
}

.modal_date_pick_col {
  width: 49%;
}

.modal_main_info_elem.repeat {
  display: flex;
  flex-direction: row;
}

.modal_event_btns {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.modal_event_btns button {
  display: flex;
  justify-content: center;
  background-color: #3B9ED4;
  color: #fff;
  font-size: 15px;
  line-height: 1;
  font-weight: normal;
  border: 0;
  border-radius: 20px;
  cursor: pointer;
  text-align: center;
  padding: 10px 12px;
  user-select: none;
  transition: 0.1s;
  outline: none;
  margin-left: 10px;
}

.modal_event_btns button:hover {
  background-color: #037cae;
}

.modal_event_btns button:focus {
  background-color: #037cae;
}

.modal_main_info_elem label.modal_main_info_elem_check {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.modal_main_info_elem label.modal_main_info_elem_check span {
  margin-bottom: 0;
  margin-right: 10px;

}

.info_file {
  cursor: pointer;
}

.select_color {
  position: absolute;
  width: 210px;
  padding: 10px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 99999;
  height: auto;
  top: -270px;
  border-radius: 30px;
  justify-content: flex-start;
  overflow: auto;
  box-shadow: 0px 0px 4px -1px;
}

.select_color.left {
  left: 0;
}

.select_color.right {
  right: 0;
}

.select_color_btn {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.select_color_btn div {
  padding: 7px;
  margin-right: 5px;
  cursor: pointer;
  transition: all .3s;
  font-weight: normal;
  border: 0;
  border-radius: 5px;
  text-align: center;
  background-color: #7cb4d3;
  color: white;
}

.url_add_elem {
  border-radius: 40px;
  margin-left: 3px;
  box-shadow: 0px 0px 5px -2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  padding: 0;
  margin-top: 10px;
  cursor: pointer;
}

.url_add_elem svg {
  width: 30px;
  height: 30px;
}

.modal_main_info_elem.more_elems {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select_user {
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 99;
  box-shadow: 0px 0px 8px -3px;
  border-radius: 0px 0px 10px 10px;
  padding: 5px 0;
  overflow: auto;
  max-height: 90px;
  position: absolute;
  width: 100%;
  top: 55px;
}


.modal_main_info_elem label span.select_user_elem {
  padding: 5px;
  padding-bottom: 8px;
  margin: 0;
  border-bottom: 1px solid #e2e2e2;
  cursor: pointer;
  transition: all .3s;
}

.modal_main_info_elem label span.select_user_elem:hover {
  background-color: #3B9ED4;
  color: white;
}

.modal_main_info_elem label span.select_user_elem.not_active {
  pointer-events: none;
  border: none;
}

.modal_main_info_elem.error span, .modal_main_info_elem.error {
  color: red;
  font-weight: bold;
}

button.error {
  pointer-events: none;
  background-color: #c1c1c1;
}

@media (max-width: 700px) {
  .modal {
    padding: 10px 5px;
  }
}

@media (max-width: 470px) {
  .modal_date_pick {
    flex-direction: column;
  }

  .modal_date_pick_col {
    width: 100%;
  }

  .modal_date_pick .modal_date_pick_elem {
    max-width: none;
    margin-bottom: 20px;
  }
}

.modal_main_info_elem_binds_elem {
  box-shadow: 0px 0px 5px -3px;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
}

.modal_main_info_elem_binds_elem svg {
  width: 20px;
}

.modal_main_info_elem_binds_elem svg path {
  width: 20px;
  stroke: red;
  cursor: pointer;
}

.color_pick {
  display: flex;
  justify-content: space-between;
}

.color_pick_elem {
  display: flex;
  width: 100%;
}

.color_pick_elem label {
  width: 90%;
}

.info_file svg {
  width: 30px;
}

</style>
